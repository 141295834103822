<template>
	<ScwLayoutPageMeta :title="title" />
	<ScwNavigationHeaderNav v-if="!isHome" fixed />
	<transition leave-active-class="transition-opacity duration-500" leave-to-class="opacity-0" mode="out-in">
		<div
			v-if="hidePage !== true"
			id="page-wrapper"
			ref="pageRef"
			:key="processedUrl"
			class="flex flex-col min-h-screen"
		>
			<main
				class="flex-grow"
				:style="{
					paddingTop: !isHome && mq.mdPlus ? 'calc(var(--wp-admin--admin-bar--height, 0px) + 48px)' : '0rem',
				}"
			>
				<slot v-bind="$attrs"></slot>
			</main>
		</div>
	</transition>
	<ScwLayoutSiteFooter />
	<!-- Page transition display -->
	<teleport to="#vue-modals">
		<div
			v-show="isLoading"
			ref="loaderRef"
			class="fixed pointer-events-none top-0 left-0 h-screen w-screen bg-dark-900 flex justify-center items-center z-[9999]"
		>
			<div class="overflow-hidden">
				<div ref="logoRef" class="opacity-0">
					<ScwLogosScwText class="w-80 text-white" />
				</div>
			</div>
		</div>
	</teleport>
	<ScwLayoutFooterScripts />
	<Vue3Snackbar
		bottom
		dense
		groups
		shadow
		border="left"
		background-opacity="0"
		base-background-color="#1d1d1d"
		:duration="4000"
	/>
</template>

<script setup>
import { useMq } from "vue3-mq";
import { Vue3Snackbar } from "vue3-snackbar";
import { router, usePage } from "@inertiajs/vue3";
import { watchOnce } from "@vueuse/core";
import { gsap } from "gsap";
import "vue3-snackbar/styles";
import { useSettingsStore } from "store";

const title = computed(() => usePage().props.page?.title);
const isHome = computed(() => usePage().component === "Home");

const mq = useMq();

const settingsStore = useSettingsStore();
let pauseTransitions = ref(false);

let hidePage = ref(false);
let pageReady = ref(false);
let isLoading = ref(false);
let loaderRef = ref(null);
let logoRef = ref(null);
let pageRef = ref(null);

const hideOverlay = () => {
	hidePage.value = false;
	const timeline = gsap.timeline({ onComplete: () => (isLoading.value = false) });
	timeline
		.to(logoRef.value, { opacity: 1, translateY: "100%" })
		.to(loaderRef.value, { translateY: "-100%", duration: 0.3 })
		.to(logoRef.value, { opacity: 0, duration: 0 });
};

const onCompleteTransition = () => {
	if (pageReady.value === true) hideOverlay();
	else {
		watchOnce(pageReady, hideOverlay);
	}
};

onMounted(() => {
	router.on("start", (ev) => {
		if (settingsStore.pageTransitions === false) return;

		const { pathname } = window.location;
		const { pathname: targetPathname } = ev.detail.visit.url;

		if (/^\/auth/i.test(pathname) && /^\/auth/i.test(targetPathname)) {
			pauseTransitions.value = true;
			setTimeout(() => {
				pauseTransitions.value = false;
			}, 1000);
			return;
		}

		const isSamePage = pathname === targetPathname;
		const method = ev.detail?.method || "get";
		const target = ev.detail?.visit?.url?.href;
		if (!target || /get/i.test(method) === false || isSamePage) return;

		pageReady.value = false;
		hidePage.value = true;

		const timeline = gsap.timeline({ onComplete: onCompleteTransition });
		isLoading.value = true;

		timeline
			.fromTo(loaderRef.value, { translateY: "100%" }, { translateY: "0%", duration: 0.3 })
			.fromTo(logoRef.value, { opacity: 1, translateY: "100%" }, { opacity: 1, translateY: "0%", duration: 0.8 });
	});

	router.on("finish", () => {
		pageReady.value = true;
	});
});

const processedUrl = computed(() => {
	if (typeof window === "undefined") return usePage().url;

	const url = new URL(usePage().url);
	return url.pathname;
});
</script>

<style>
.page-wipe-enter-active {
	animation: wipe-from-below 0.5s;
}
.page-wipe-leave-active {
	animation: wipe-to-above 0.5s;
}
</style>
