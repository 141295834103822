import { useSlots, computed } from "vue";

export const useNormalisedContent = (props, config = {}) => {
	const { textProp = "label" } = config;

	return computed(() => {
		const slots = useSlots();
		if (props[textProp]) return props[textProp];
		const slot = slots.default && slots.default();
		const children = slot?.[0]?.children;
		return Array.isArray(children) ? children[0].children || "" : children || "";
	});
};
