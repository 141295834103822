import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";

export const useMenuByLocation = (slug) => {
	return computed(() => {
		const locationEntry = usePage().props?.menuLocations?.[slug];
		if (!locationEntry || !locationEntry.assigned) return null;
		else return usePage().props.menus.find((menu) => menu.id === locationEntry.assigned);
	});
};
