import { createSSRApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { pinia } from "./store";
import { resolvePageComponent } from "germinal";
import { Vue3Mq } from "vue3-mq";
import { registerComponents } from "./setup";
import { SnackbarService } from "vue3-snackbar";
import "../css/style.css";

createInertiaApp({
	resolve: (name) =>
		resolvePageComponent({
			name,
			pages: import.meta.glob("./pages/**/*.vue", { eager: false }),
			templates: import.meta.glob("./templates/**/*.vue", { eager: true }),
		}),
	setup({ el, App, props, plugin }) {
		createSSRApp({ render: () => h(App, props) })
			.use(plugin)
			.use(pinia)
			.use(Vue3Mq, {
				preset: "tailwind",
			})
			.use(SnackbarService)
			.use(registerComponents)
			.mount(el);
	},
	title: (title) => `${title} | southcoastweb`,
});
