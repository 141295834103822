<template>
	<div class="mb-4 flex flex-wrap gap-4">
		<Link
			v-for="tag in props.tags"
			:key="tag.id"
			v-ripple
			:href="tag.link"
			class="border border-secondary-500 rounded px-2 py-0 uppercase bg-secondary-900 text-white relative before:absolute before:inset-0 before:bg-current before:opacity-0 before:transition-opacity hover:before:opacity-20"
			>{{ tag.name }}</Link
		>
	</div>
</template>

<script setup>
import { vRipple } from "directives";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
	tags: {
		type: Array,
		default: () => [],
	},
});
</script>
