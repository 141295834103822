<template>
	<div class="absolute inset-0 pointer-events-none -translate-y-8 translate-x-10">
		<div class="absolute right-0 top-0 bottom-0 aspect-square max-w-full max-h-full">
			<div
				class="upper-shape absolute inset-0 bg-gradient-to-tr from-secondary-900 to-primary-900 -translate-y-1/4"
			></div>
			<div
				class="lower-shape h-full max-h-full relative bg-gradient-to-tr from-primary-900 to-secondary-900 translate-y-1/4"
			>
				<ScwDisplayResponsiveImage v-if="image" :media="image" />
				<div class="overlay absolute inset-0"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";

const image = computed(() => usePage().props.page?.featuredImage || { exists: false });
</script>

<style lang="postcss" scoped>
.scw-featured-image--variant {
	max-height: 100vh;
}
.upper-shape {
	clip-path: polygon(45% 55%, 0% 80%, 0% 50%, 45% 25%, 45% 55%, 55% 55%, 55% 25%, 100% 50%, 100% 80%, 55% 55%);
}
.lower-shape {
	clip-path: polygon(
		45% 55%,
		0 30%,
		0 0,
		45% 25%,
		45% 55%,
		/* part 2 */ 55% 55%,
		55% 25%,
		100% 0%,
		100% 30%,
		55% 55%
	);
}

.overlay {
	background-image: linear-gradient(to top, transparent 0%, transparent calc(100% - 150px), rgba(0, 0, 0, 0.9));
}
</style>
