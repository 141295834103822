<template>
	<ScwLayoutPageMeta :title="title" />
	<main class="h-screen w-full grid grid-cols-6">
		<div class="col-span-4 relative h-full">
			<img :src="Background" class="object-cover h-screen w-full" />
			<div
				class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-dark-900/50 backdrop-blur rounded-xl"
			>
				<ScwLogosScwText class="text-white h-40 drop-shadow-2xl" />
			</div>
		</div>
		<div class="col-span-2 bg-dark-900 text-white">
			<Transition
				enter-active-class="transition-all duration-300"
				leave-active-class="transition-all duration-300"
				enter-from-class="opacity-0"
				leave-to-class="opacity-0"
				mode="out-in"
			>
				<div :key="$page.url" class="w-full h-full">
					<slot></slot>
				</div>
			</Transition>
		</div>
	</main>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import Background from "../../images/backgrounds/durdle-door-dark.jpg";

const title = computed(() => usePage().props.page?.title);
</script>
