<template>
	<ScwButton
		v-bind="$attrs"
		flat
		background-class="bg-white"
		text-class="text-primary-900 font-medium"
		border-class="border-2 border-solid border-primary-900"
	>
		<template #prepend>
			<slot name="prepend"></slot>
		</template>
		<slot></slot>
		<template #append>
			<slot name="append"></slot>
		</template>
	</ScwButton>
</template>

<script setup></script>
