import { defineComponent as zn, openBlock as Gn, createElementBlock as Kn, computed as Jt, onMounted as Jn, nextTick as Wn, onUnmounted as kn, unref as Vn, getCurrentInstance as Xn, ref as Yn, reactive as Zn, watch as Qn } from "vue";
import { usePage as ye, router as er } from "@inertiajs/vue3";
const yu = /* @__PURE__ */ zn({
  __name: "Test",
  setup(e) {
    return console.log("Testing"), (t, n) => (Gn(), Kn("div", null, "Testing"));
  }
});
const bu = () => Jt(() => {
  var n;
  const e = (n = ye().props) == null ? void 0 : n.modules;
  e.acf || console.warn("Germinal: ACF doesn't appear to be activated on this Wordpress installation");
  const t = (e == null ? void 0 : e.acf) || {};
  return Object.entries(t).reduce((r, [s, o]) => (o.value !== void 0 && (r[s] = o.value), r), {});
});
function Wt(e, t) {
  return function() {
    return e.apply(t, arguments);
  };
}
const { toString: tr } = Object.prototype, { getPrototypeOf: ke } = Object, be = ((e) => (t) => {
  const n = tr.call(t);
  return e[n] || (e[n] = n.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), N = (e) => (e = e.toLowerCase(), (t) => be(t) === e), we = (e) => (t) => typeof t === e, { isArray: W } = Array, Z = we("undefined");
function nr(e) {
  return e !== null && !Z(e) && e.constructor !== null && !Z(e.constructor) && x(e.constructor.isBuffer) && e.constructor.isBuffer(e);
}
const kt = N("ArrayBuffer");
function rr(e) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && kt(e.buffer), t;
}
const sr = we("string"), x = we("function"), Vt = we("number"), Te = (e) => e !== null && typeof e == "object", or = (e) => e === !0 || e === !1, ae = (e) => {
  if (be(e) !== "object")
    return !1;
  const t = ke(e);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in e) && !(Symbol.iterator in e);
}, ir = N("Date"), ar = N("File"), cr = N("Blob"), ur = N("FileList"), lr = (e) => Te(e) && x(e.pipe), fr = (e) => {
  let t;
  return e && (typeof FormData == "function" && e instanceof FormData || x(e.append) && ((t = be(e)) === "formdata" || // detect form-data instance
  t === "object" && x(e.toString) && e.toString() === "[object FormData]"));
}, dr = N("URLSearchParams"), [pr, hr, mr, gr] = ["ReadableStream", "Request", "Response", "Headers"].map(N), yr = (e) => e.trim ? e.trim() : e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function re(e, t, { allOwnKeys: n = !1 } = {}) {
  if (e === null || typeof e > "u")
    return;
  let r, s;
  if (typeof e != "object" && (e = [e]), W(e))
    for (r = 0, s = e.length; r < s; r++)
      t.call(null, e[r], r, e);
  else {
    const o = n ? Object.getOwnPropertyNames(e) : Object.keys(e), i = o.length;
    let a;
    for (r = 0; r < i; r++)
      a = o[r], t.call(null, e[a], a, e);
  }
}
function Xt(e, t) {
  t = t.toLowerCase();
  const n = Object.keys(e);
  let r = n.length, s;
  for (; r-- > 0; )
    if (s = n[r], t === s.toLowerCase())
      return s;
  return null;
}
const Yt = (() => typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global)(), Zt = (e) => !Z(e) && e !== Yt;
function Fe() {
  const { caseless: e } = Zt(this) && this || {}, t = {}, n = (r, s) => {
    const o = e && Xt(t, s) || s;
    ae(t[o]) && ae(r) ? t[o] = Fe(t[o], r) : ae(r) ? t[o] = Fe({}, r) : W(r) ? t[o] = r.slice() : t[o] = r;
  };
  for (let r = 0, s = arguments.length; r < s; r++)
    arguments[r] && re(arguments[r], n);
  return t;
}
const br = (e, t, n, { allOwnKeys: r } = {}) => (re(t, (s, o) => {
  n && x(s) ? e[o] = Wt(s, n) : e[o] = s;
}, { allOwnKeys: r }), e), wr = (e) => (e.charCodeAt(0) === 65279 && (e = e.slice(1)), e), Tr = (e, t, n, r) => {
  e.prototype = Object.create(t.prototype, r), e.prototype.constructor = e, Object.defineProperty(e, "super", {
    value: t.prototype
  }), n && Object.assign(e.prototype, n);
}, Er = (e, t, n, r) => {
  let s, o, i;
  const a = {};
  if (t = t || {}, e == null)
    return t;
  do {
    for (s = Object.getOwnPropertyNames(e), o = s.length; o-- > 0; )
      i = s[o], (!r || r(i, e, t)) && !a[i] && (t[i] = e[i], a[i] = !0);
    e = n !== !1 && ke(e);
  } while (e && (!n || n(e, t)) && e !== Object.prototype);
  return t;
}, _r = (e, t, n) => {
  e = String(e), (n === void 0 || n > e.length) && (n = e.length), n -= t.length;
  const r = e.indexOf(t, n);
  return r !== -1 && r === n;
}, Sr = (e) => {
  if (!e)
    return null;
  if (W(e))
    return e;
  let t = e.length;
  if (!Vt(t))
    return null;
  const n = new Array(t);
  for (; t-- > 0; )
    n[t] = e[t];
  return n;
}, Ar = ((e) => (t) => e && t instanceof e)(typeof Uint8Array < "u" && ke(Uint8Array)), Or = (e, t) => {
  const r = (e && e[Symbol.iterator]).call(e);
  let s;
  for (; (s = r.next()) && !s.done; ) {
    const o = s.value;
    t.call(e, o[0], o[1]);
  }
}, Rr = (e, t) => {
  let n;
  const r = [];
  for (; (n = e.exec(t)) !== null; )
    r.push(n);
  return r;
}, $r = N("HTMLFormElement"), vr = (e) => e.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(n, r, s) {
    return r.toUpperCase() + s;
  }
), ct = (({ hasOwnProperty: e }) => (t, n) => e.call(t, n))(Object.prototype), Pr = N("RegExp"), Qt = (e, t) => {
  const n = Object.getOwnPropertyDescriptors(e), r = {};
  re(n, (s, o) => {
    let i;
    (i = t(s, o, e)) !== !1 && (r[o] = i || s);
  }), Object.defineProperties(e, r);
}, xr = (e) => {
  Qt(e, (t, n) => {
    if (x(e) && ["arguments", "caller", "callee"].indexOf(n) !== -1)
      return !1;
    const r = e[n];
    if (x(r)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + n + "'");
      });
    }
  });
}, Cr = (e, t) => {
  const n = {}, r = (s) => {
    s.forEach((o) => {
      n[o] = !0;
    });
  };
  return W(e) ? r(e) : r(String(e).split(t)), n;
}, jr = () => {
}, Nr = (e, t) => e != null && Number.isFinite(e = +e) ? e : t, $e = "abcdefghijklmnopqrstuvwxyz", ut = "0123456789", en = {
  DIGIT: ut,
  ALPHA: $e,
  ALPHA_DIGIT: $e + $e.toUpperCase() + ut
}, Lr = (e = 16, t = en.ALPHA_DIGIT) => {
  let n = "";
  const { length: r } = t;
  for (; e--; )
    n += t[Math.random() * r | 0];
  return n;
};
function Dr(e) {
  return !!(e && x(e.append) && e[Symbol.toStringTag] === "FormData" && e[Symbol.iterator]);
}
const Fr = (e) => {
  const t = new Array(10), n = (r, s) => {
    if (Te(r)) {
      if (t.indexOf(r) >= 0)
        return;
      if (!("toJSON" in r)) {
        t[s] = r;
        const o = W(r) ? [] : {};
        return re(r, (i, a) => {
          const f = n(i, s + 1);
          !Z(f) && (o[a] = f);
        }), t[s] = void 0, o;
      }
    }
    return r;
  };
  return n(e, 0);
}, Ur = N("AsyncFunction"), Br = (e) => e && (Te(e) || x(e)) && x(e.then) && x(e.catch), c = {
  isArray: W,
  isArrayBuffer: kt,
  isBuffer: nr,
  isFormData: fr,
  isArrayBufferView: rr,
  isString: sr,
  isNumber: Vt,
  isBoolean: or,
  isObject: Te,
  isPlainObject: ae,
  isReadableStream: pr,
  isRequest: hr,
  isResponse: mr,
  isHeaders: gr,
  isUndefined: Z,
  isDate: ir,
  isFile: ar,
  isBlob: cr,
  isRegExp: Pr,
  isFunction: x,
  isStream: lr,
  isURLSearchParams: dr,
  isTypedArray: Ar,
  isFileList: ur,
  forEach: re,
  merge: Fe,
  extend: br,
  trim: yr,
  stripBOM: wr,
  inherits: Tr,
  toFlatObject: Er,
  kindOf: be,
  kindOfTest: N,
  endsWith: _r,
  toArray: Sr,
  forEachEntry: Or,
  matchAll: Rr,
  isHTMLForm: $r,
  hasOwnProperty: ct,
  hasOwnProp: ct,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: Qt,
  freezeMethods: xr,
  toObjectSet: Cr,
  toCamelCase: vr,
  noop: jr,
  toFiniteNumber: Nr,
  findKey: Xt,
  global: Yt,
  isContextDefined: Zt,
  ALPHABET: en,
  generateString: Lr,
  isSpecCompliantForm: Dr,
  toJSONObject: Fr,
  isAsyncFn: Ur,
  isThenable: Br
};
function w(e, t, n, r, s) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = e, this.name = "AxiosError", t && (this.code = t), n && (this.config = n), r && (this.request = r), s && (this.response = s);
}
c.inherits(w, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: c.toJSONObject(this.config),
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null
    };
  }
});
const tn = w.prototype, nn = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((e) => {
  nn[e] = { value: e };
});
Object.defineProperties(w, nn);
Object.defineProperty(tn, "isAxiosError", { value: !0 });
w.from = (e, t, n, r, s, o) => {
  const i = Object.create(tn);
  return c.toFlatObject(e, i, function(f) {
    return f !== Error.prototype;
  }, (a) => a !== "isAxiosError"), w.call(i, e.message, t, n, r, s), i.cause = e, i.name = e.name, o && Object.assign(i, o), i;
};
const Ir = null;
function Ue(e) {
  return c.isPlainObject(e) || c.isArray(e);
}
function rn(e) {
  return c.endsWith(e, "[]") ? e.slice(0, -2) : e;
}
function lt(e, t, n) {
  return e ? e.concat(t).map(function(s, o) {
    return s = rn(s), !n && o ? "[" + s + "]" : s;
  }).join(n ? "." : "") : t;
}
function Mr(e) {
  return c.isArray(e) && !e.some(Ue);
}
const Hr = c.toFlatObject(c, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function Ee(e, t, n) {
  if (!c.isObject(e))
    throw new TypeError("target must be an object");
  t = t || new FormData(), n = c.toFlatObject(n, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(d, m) {
    return !c.isUndefined(m[d]);
  });
  const r = n.metaTokens, s = n.visitor || u, o = n.dots, i = n.indexes, f = (n.Blob || typeof Blob < "u" && Blob) && c.isSpecCompliantForm(t);
  if (!c.isFunction(s))
    throw new TypeError("visitor must be a function");
  function l(h) {
    if (h === null)
      return "";
    if (c.isDate(h))
      return h.toISOString();
    if (!f && c.isBlob(h))
      throw new w("Blob is not supported. Use a Buffer instead.");
    return c.isArrayBuffer(h) || c.isTypedArray(h) ? f && typeof Blob == "function" ? new Blob([h]) : Buffer.from(h) : h;
  }
  function u(h, d, m) {
    let g = h;
    if (h && !m && typeof h == "object") {
      if (c.endsWith(d, "{}"))
        d = r ? d : d.slice(0, -2), h = JSON.stringify(h);
      else if (c.isArray(h) && Mr(h) || (c.isFileList(h) || c.endsWith(d, "[]")) && (g = c.toArray(h)))
        return d = rn(d), g.forEach(function(E, $) {
          !(c.isUndefined(E) || E === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            i === !0 ? lt([d], $, o) : i === null ? d : d + "[]",
            l(E)
          );
        }), !1;
    }
    return Ue(h) ? !0 : (t.append(lt(m, d, o), l(h)), !1);
  }
  const p = [], b = Object.assign(Hr, {
    defaultVisitor: u,
    convertValue: l,
    isVisitable: Ue
  });
  function y(h, d) {
    if (!c.isUndefined(h)) {
      if (p.indexOf(h) !== -1)
        throw Error("Circular reference detected in " + d.join("."));
      p.push(h), c.forEach(h, function(g, _) {
        (!(c.isUndefined(g) || g === null) && s.call(
          t,
          g,
          c.isString(_) ? _.trim() : _,
          d,
          b
        )) === !0 && y(g, d ? d.concat(_) : [_]);
      }), p.pop();
    }
  }
  if (!c.isObject(e))
    throw new TypeError("data must be an object");
  return y(e), t;
}
function ft(e) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(e).replace(/[!'()~]|%20|%00/g, function(r) {
    return t[r];
  });
}
function Ve(e, t) {
  this._pairs = [], e && Ee(e, this, t);
}
const sn = Ve.prototype;
sn.append = function(t, n) {
  this._pairs.push([t, n]);
};
sn.toString = function(t) {
  const n = t ? function(r) {
    return t.call(this, r, ft);
  } : ft;
  return this._pairs.map(function(s) {
    return n(s[0]) + "=" + n(s[1]);
  }, "").join("&");
};
function qr(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function on(e, t, n) {
  if (!t)
    return e;
  const r = n && n.encode || qr, s = n && n.serialize;
  let o;
  if (s ? o = s(t, n) : o = c.isURLSearchParams(t) ? t.toString() : new Ve(t, n).toString(r), o) {
    const i = e.indexOf("#");
    i !== -1 && (e = e.slice(0, i)), e += (e.indexOf("?") === -1 ? "?" : "&") + o;
  }
  return e;
}
class zr {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, n, r) {
    return this.handlers.push({
      fulfilled: t,
      rejected: n,
      synchronous: r ? r.synchronous : !1,
      runWhen: r ? r.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    c.forEach(this.handlers, function(r) {
      r !== null && t(r);
    });
  }
}
const dt = zr, an = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Gr = typeof URLSearchParams < "u" ? URLSearchParams : Ve, Kr = typeof FormData < "u" ? FormData : null, Jr = typeof Blob < "u" ? Blob : null, Wr = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Gr,
    FormData: Kr,
    Blob: Jr
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, Xe = typeof window < "u" && typeof document < "u", kr = ((e) => Xe && ["ReactNative", "NativeScript", "NS"].indexOf(e) < 0)(typeof navigator < "u" && navigator.product), Vr = (() => typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function")(), Xr = Xe && window.location.href || "http://localhost", Yr = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: Xe,
  hasStandardBrowserEnv: kr,
  hasStandardBrowserWebWorkerEnv: Vr,
  origin: Xr
}, Symbol.toStringTag, { value: "Module" })), C = {
  ...Yr,
  ...Wr
};
function Zr(e, t) {
  return Ee(e, new C.classes.URLSearchParams(), Object.assign({
    visitor: function(n, r, s, o) {
      return C.isNode && c.isBuffer(n) ? (this.append(r, n.toString("base64")), !1) : o.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function Qr(e) {
  return c.matchAll(/\w+|\[(\w*)]/g, e).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function es(e) {
  const t = {}, n = Object.keys(e);
  let r;
  const s = n.length;
  let o;
  for (r = 0; r < s; r++)
    o = n[r], t[o] = e[o];
  return t;
}
function cn(e) {
  function t(n, r, s, o) {
    let i = n[o++];
    if (i === "__proto__")
      return !0;
    const a = Number.isFinite(+i), f = o >= n.length;
    return i = !i && c.isArray(s) ? s.length : i, f ? (c.hasOwnProp(s, i) ? s[i] = [s[i], r] : s[i] = r, !a) : ((!s[i] || !c.isObject(s[i])) && (s[i] = []), t(n, r, s[i], o) && c.isArray(s[i]) && (s[i] = es(s[i])), !a);
  }
  if (c.isFormData(e) && c.isFunction(e.entries)) {
    const n = {};
    return c.forEachEntry(e, (r, s) => {
      t(Qr(r), s, n, 0);
    }), n;
  }
  return null;
}
function ts(e, t, n) {
  if (c.isString(e))
    try {
      return (t || JSON.parse)(e), c.trim(e);
    } catch (r) {
      if (r.name !== "SyntaxError")
        throw r;
    }
  return (n || JSON.stringify)(e);
}
const Ye = {
  transitional: an,
  adapter: ["xhr", "http", "fetch"],
  transformRequest: [function(t, n) {
    const r = n.getContentType() || "", s = r.indexOf("application/json") > -1, o = c.isObject(t);
    if (o && c.isHTMLForm(t) && (t = new FormData(t)), c.isFormData(t))
      return s ? JSON.stringify(cn(t)) : t;
    if (c.isArrayBuffer(t) || c.isBuffer(t) || c.isStream(t) || c.isFile(t) || c.isBlob(t) || c.isReadableStream(t))
      return t;
    if (c.isArrayBufferView(t))
      return t.buffer;
    if (c.isURLSearchParams(t))
      return n.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let a;
    if (o) {
      if (r.indexOf("application/x-www-form-urlencoded") > -1)
        return Zr(t, this.formSerializer).toString();
      if ((a = c.isFileList(t)) || r.indexOf("multipart/form-data") > -1) {
        const f = this.env && this.env.FormData;
        return Ee(
          a ? { "files[]": t } : t,
          f && new f(),
          this.formSerializer
        );
      }
    }
    return o || s ? (n.setContentType("application/json", !1), ts(t)) : t;
  }],
  transformResponse: [function(t) {
    const n = this.transitional || Ye.transitional, r = n && n.forcedJSONParsing, s = this.responseType === "json";
    if (c.isResponse(t) || c.isReadableStream(t))
      return t;
    if (t && c.isString(t) && (r && !this.responseType || s)) {
      const i = !(n && n.silentJSONParsing) && s;
      try {
        return JSON.parse(t);
      } catch (a) {
        if (i)
          throw a.name === "SyntaxError" ? w.from(a, w.ERR_BAD_RESPONSE, this, null, this.response) : a;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: C.classes.FormData,
    Blob: C.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
c.forEach(["delete", "get", "head", "post", "put", "patch"], (e) => {
  Ye.headers[e] = {};
});
const Ze = Ye, ns = c.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), rs = (e) => {
  const t = {};
  let n, r, s;
  return e && e.split(`
`).forEach(function(i) {
    s = i.indexOf(":"), n = i.substring(0, s).trim().toLowerCase(), r = i.substring(s + 1).trim(), !(!n || t[n] && ns[n]) && (n === "set-cookie" ? t[n] ? t[n].push(r) : t[n] = [r] : t[n] = t[n] ? t[n] + ", " + r : r);
  }), t;
}, pt = Symbol("internals");
function V(e) {
  return e && String(e).trim().toLowerCase();
}
function ce(e) {
  return e === !1 || e == null ? e : c.isArray(e) ? e.map(ce) : String(e);
}
function ss(e) {
  const t = /* @__PURE__ */ Object.create(null), n = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let r;
  for (; r = n.exec(e); )
    t[r[1]] = r[2];
  return t;
}
const os = (e) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(e.trim());
function ve(e, t, n, r, s) {
  if (c.isFunction(r))
    return r.call(this, t, n);
  if (s && (t = n), !!c.isString(t)) {
    if (c.isString(r))
      return t.indexOf(r) !== -1;
    if (c.isRegExp(r))
      return r.test(t);
  }
}
function is(e) {
  return e.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, n, r) => n.toUpperCase() + r);
}
function as(e, t) {
  const n = c.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((r) => {
    Object.defineProperty(e, r + n, {
      value: function(s, o, i) {
        return this[r].call(this, t, s, o, i);
      },
      configurable: !0
    });
  });
}
class _e {
  constructor(t) {
    t && this.set(t);
  }
  set(t, n, r) {
    const s = this;
    function o(a, f, l) {
      const u = V(f);
      if (!u)
        throw new Error("header name must be a non-empty string");
      const p = c.findKey(s, u);
      (!p || s[p] === void 0 || l === !0 || l === void 0 && s[p] !== !1) && (s[p || f] = ce(a));
    }
    const i = (a, f) => c.forEach(a, (l, u) => o(l, u, f));
    if (c.isPlainObject(t) || t instanceof this.constructor)
      i(t, n);
    else if (c.isString(t) && (t = t.trim()) && !os(t))
      i(rs(t), n);
    else if (c.isHeaders(t))
      for (const [a, f] of t.entries())
        o(f, a, r);
    else
      t != null && o(n, t, r);
    return this;
  }
  get(t, n) {
    if (t = V(t), t) {
      const r = c.findKey(this, t);
      if (r) {
        const s = this[r];
        if (!n)
          return s;
        if (n === !0)
          return ss(s);
        if (c.isFunction(n))
          return n.call(this, s, r);
        if (c.isRegExp(n))
          return n.exec(s);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, n) {
    if (t = V(t), t) {
      const r = c.findKey(this, t);
      return !!(r && this[r] !== void 0 && (!n || ve(this, this[r], r, n)));
    }
    return !1;
  }
  delete(t, n) {
    const r = this;
    let s = !1;
    function o(i) {
      if (i = V(i), i) {
        const a = c.findKey(r, i);
        a && (!n || ve(r, r[a], a, n)) && (delete r[a], s = !0);
      }
    }
    return c.isArray(t) ? t.forEach(o) : o(t), s;
  }
  clear(t) {
    const n = Object.keys(this);
    let r = n.length, s = !1;
    for (; r--; ) {
      const o = n[r];
      (!t || ve(this, this[o], o, t, !0)) && (delete this[o], s = !0);
    }
    return s;
  }
  normalize(t) {
    const n = this, r = {};
    return c.forEach(this, (s, o) => {
      const i = c.findKey(r, o);
      if (i) {
        n[i] = ce(s), delete n[o];
        return;
      }
      const a = t ? is(o) : String(o).trim();
      a !== o && delete n[o], n[a] = ce(s), r[a] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const n = /* @__PURE__ */ Object.create(null);
    return c.forEach(this, (r, s) => {
      r != null && r !== !1 && (n[s] = t && c.isArray(r) ? r.join(", ") : r);
    }), n;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, n]) => t + ": " + n).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...n) {
    const r = new this(t);
    return n.forEach((s) => r.set(s)), r;
  }
  static accessor(t) {
    const r = (this[pt] = this[pt] = {
      accessors: {}
    }).accessors, s = this.prototype;
    function o(i) {
      const a = V(i);
      r[a] || (as(s, i), r[a] = !0);
    }
    return c.isArray(t) ? t.forEach(o) : o(t), this;
  }
}
_e.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
c.reduceDescriptors(_e.prototype, ({ value: e }, t) => {
  let n = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => e,
    set(r) {
      this[n] = r;
    }
  };
});
c.freezeMethods(_e);
const j = _e;
function Pe(e, t) {
  const n = this || Ze, r = t || n, s = j.from(r.headers);
  let o = r.data;
  return c.forEach(e, function(a) {
    o = a.call(n, o, s.normalize(), t ? t.status : void 0);
  }), s.normalize(), o;
}
function un(e) {
  return !!(e && e.__CANCEL__);
}
function k(e, t, n) {
  w.call(this, e ?? "canceled", w.ERR_CANCELED, t, n), this.name = "CanceledError";
}
c.inherits(k, w, {
  __CANCEL__: !0
});
function ln(e, t, n) {
  const r = n.config.validateStatus;
  !n.status || !r || r(n.status) ? e(n) : t(new w(
    "Request failed with status code " + n.status,
    [w.ERR_BAD_REQUEST, w.ERR_BAD_RESPONSE][Math.floor(n.status / 100) - 4],
    n.config,
    n.request,
    n
  ));
}
function cs(e) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(e);
  return t && t[1] || "";
}
function us(e, t) {
  e = e || 10;
  const n = new Array(e), r = new Array(e);
  let s = 0, o = 0, i;
  return t = t !== void 0 ? t : 1e3, function(f) {
    const l = Date.now(), u = r[o];
    i || (i = l), n[s] = f, r[s] = l;
    let p = o, b = 0;
    for (; p !== s; )
      b += n[p++], p = p % e;
    if (s = (s + 1) % e, s === o && (o = (o + 1) % e), l - i < t)
      return;
    const y = u && l - u;
    return y ? Math.round(b * 1e3 / y) : void 0;
  };
}
function ls(e, t) {
  let n = 0;
  const r = 1e3 / t;
  let s = null;
  return function() {
    const i = this === !0, a = Date.now();
    if (i || a - n > r)
      return s && (clearTimeout(s), s = null), n = a, e.apply(null, arguments);
    s || (s = setTimeout(() => (s = null, n = Date.now(), e.apply(null, arguments)), r - (a - n)));
  };
}
const fe = (e, t, n = 3) => {
  let r = 0;
  const s = us(50, 250);
  return ls((o) => {
    const i = o.loaded, a = o.lengthComputable ? o.total : void 0, f = i - r, l = s(f), u = i <= a;
    r = i;
    const p = {
      loaded: i,
      total: a,
      progress: a ? i / a : void 0,
      bytes: f,
      rate: l || void 0,
      estimated: l && a && u ? (a - i) / l : void 0,
      event: o,
      lengthComputable: a != null
    };
    p[t ? "download" : "upload"] = !0, e(p);
  }, n);
}, fs = C.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = /(msie|trident)/i.test(navigator.userAgent), n = document.createElement("a");
    let r;
    function s(o) {
      let i = o;
      return t && (n.setAttribute("href", i), i = n.href), n.setAttribute("href", i), {
        href: n.href,
        protocol: n.protocol ? n.protocol.replace(/:$/, "") : "",
        host: n.host,
        search: n.search ? n.search.replace(/^\?/, "") : "",
        hash: n.hash ? n.hash.replace(/^#/, "") : "",
        hostname: n.hostname,
        port: n.port,
        pathname: n.pathname.charAt(0) === "/" ? n.pathname : "/" + n.pathname
      };
    }
    return r = s(window.location.href), function(i) {
      const a = c.isString(i) ? s(i) : i;
      return a.protocol === r.protocol && a.host === r.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  function() {
    return function() {
      return !0;
    };
  }()
), ds = C.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(e, t, n, r, s, o) {
      const i = [e + "=" + encodeURIComponent(t)];
      c.isNumber(n) && i.push("expires=" + new Date(n).toGMTString()), c.isString(r) && i.push("path=" + r), c.isString(s) && i.push("domain=" + s), o === !0 && i.push("secure"), document.cookie = i.join("; ");
    },
    read(e) {
      const t = document.cookie.match(new RegExp("(^|;\\s*)(" + e + ")=([^;]*)"));
      return t ? decodeURIComponent(t[3]) : null;
    },
    remove(e) {
      this.write(e, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function ps(e) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(e);
}
function hs(e, t) {
  return t ? e.replace(/\/?\/$/, "") + "/" + t.replace(/^\/+/, "") : e;
}
function fn(e, t) {
  return e && !ps(t) ? hs(e, t) : t;
}
const ht = (e) => e instanceof j ? { ...e } : e;
function M(e, t) {
  t = t || {};
  const n = {};
  function r(l, u, p) {
    return c.isPlainObject(l) && c.isPlainObject(u) ? c.merge.call({ caseless: p }, l, u) : c.isPlainObject(u) ? c.merge({}, u) : c.isArray(u) ? u.slice() : u;
  }
  function s(l, u, p) {
    if (c.isUndefined(u)) {
      if (!c.isUndefined(l))
        return r(void 0, l, p);
    } else
      return r(l, u, p);
  }
  function o(l, u) {
    if (!c.isUndefined(u))
      return r(void 0, u);
  }
  function i(l, u) {
    if (c.isUndefined(u)) {
      if (!c.isUndefined(l))
        return r(void 0, l);
    } else
      return r(void 0, u);
  }
  function a(l, u, p) {
    if (p in t)
      return r(l, u);
    if (p in e)
      return r(void 0, l);
  }
  const f = {
    url: o,
    method: o,
    data: o,
    baseURL: i,
    transformRequest: i,
    transformResponse: i,
    paramsSerializer: i,
    timeout: i,
    timeoutMessage: i,
    withCredentials: i,
    withXSRFToken: i,
    adapter: i,
    responseType: i,
    xsrfCookieName: i,
    xsrfHeaderName: i,
    onUploadProgress: i,
    onDownloadProgress: i,
    decompress: i,
    maxContentLength: i,
    maxBodyLength: i,
    beforeRedirect: i,
    transport: i,
    httpAgent: i,
    httpsAgent: i,
    cancelToken: i,
    socketPath: i,
    responseEncoding: i,
    validateStatus: a,
    headers: (l, u) => s(ht(l), ht(u), !0)
  };
  return c.forEach(Object.keys(Object.assign({}, e, t)), function(u) {
    const p = f[u] || s, b = p(e[u], t[u], u);
    c.isUndefined(b) && p !== a || (n[u] = b);
  }), n;
}
const dn = (e) => {
  const t = M({}, e);
  let { data: n, withXSRFToken: r, xsrfHeaderName: s, xsrfCookieName: o, headers: i, auth: a } = t;
  t.headers = i = j.from(i), t.url = on(fn(t.baseURL, t.url), e.params, e.paramsSerializer), a && i.set(
    "Authorization",
    "Basic " + btoa((a.username || "") + ":" + (a.password ? unescape(encodeURIComponent(a.password)) : ""))
  );
  let f;
  if (c.isFormData(n)) {
    if (C.hasStandardBrowserEnv || C.hasStandardBrowserWebWorkerEnv)
      i.setContentType(void 0);
    else if ((f = i.getContentType()) !== !1) {
      const [l, ...u] = f ? f.split(";").map((p) => p.trim()).filter(Boolean) : [];
      i.setContentType([l || "multipart/form-data", ...u].join("; "));
    }
  }
  if (C.hasStandardBrowserEnv && (r && c.isFunction(r) && (r = r(t)), r || r !== !1 && fs(t.url))) {
    const l = s && o && ds.read(o);
    l && i.set(s, l);
  }
  return t;
}, ms = typeof XMLHttpRequest < "u", gs = ms && function(e) {
  return new Promise(function(n, r) {
    const s = dn(e);
    let o = s.data;
    const i = j.from(s.headers).normalize();
    let { responseType: a } = s, f;
    function l() {
      s.cancelToken && s.cancelToken.unsubscribe(f), s.signal && s.signal.removeEventListener("abort", f);
    }
    let u = new XMLHttpRequest();
    u.open(s.method.toUpperCase(), s.url, !0), u.timeout = s.timeout;
    function p() {
      if (!u)
        return;
      const y = j.from(
        "getAllResponseHeaders" in u && u.getAllResponseHeaders()
      ), d = {
        data: !a || a === "text" || a === "json" ? u.responseText : u.response,
        status: u.status,
        statusText: u.statusText,
        headers: y,
        config: e,
        request: u
      };
      ln(function(g) {
        n(g), l();
      }, function(g) {
        r(g), l();
      }, d), u = null;
    }
    "onloadend" in u ? u.onloadend = p : u.onreadystatechange = function() {
      !u || u.readyState !== 4 || u.status === 0 && !(u.responseURL && u.responseURL.indexOf("file:") === 0) || setTimeout(p);
    }, u.onabort = function() {
      u && (r(new w("Request aborted", w.ECONNABORTED, s, u)), u = null);
    }, u.onerror = function() {
      r(new w("Network Error", w.ERR_NETWORK, s, u)), u = null;
    }, u.ontimeout = function() {
      let h = s.timeout ? "timeout of " + s.timeout + "ms exceeded" : "timeout exceeded";
      const d = s.transitional || an;
      s.timeoutErrorMessage && (h = s.timeoutErrorMessage), r(new w(
        h,
        d.clarifyTimeoutError ? w.ETIMEDOUT : w.ECONNABORTED,
        s,
        u
      )), u = null;
    }, o === void 0 && i.setContentType(null), "setRequestHeader" in u && c.forEach(i.toJSON(), function(h, d) {
      u.setRequestHeader(d, h);
    }), c.isUndefined(s.withCredentials) || (u.withCredentials = !!s.withCredentials), a && a !== "json" && (u.responseType = s.responseType), typeof s.onDownloadProgress == "function" && u.addEventListener("progress", fe(s.onDownloadProgress, !0)), typeof s.onUploadProgress == "function" && u.upload && u.upload.addEventListener("progress", fe(s.onUploadProgress)), (s.cancelToken || s.signal) && (f = (y) => {
      u && (r(!y || y.type ? new k(null, e, u) : y), u.abort(), u = null);
    }, s.cancelToken && s.cancelToken.subscribe(f), s.signal && (s.signal.aborted ? f() : s.signal.addEventListener("abort", f)));
    const b = cs(s.url);
    if (b && C.protocols.indexOf(b) === -1) {
      r(new w("Unsupported protocol " + b + ":", w.ERR_BAD_REQUEST, e));
      return;
    }
    u.send(o || null);
  });
}, ys = (e, t) => {
  let n = new AbortController(), r;
  const s = function(f) {
    if (!r) {
      r = !0, i();
      const l = f instanceof Error ? f : this.reason;
      n.abort(l instanceof w ? l : new k(l instanceof Error ? l.message : l));
    }
  };
  let o = t && setTimeout(() => {
    s(new w(`timeout ${t} of ms exceeded`, w.ETIMEDOUT));
  }, t);
  const i = () => {
    e && (o && clearTimeout(o), o = null, e.forEach((f) => {
      f && (f.removeEventListener ? f.removeEventListener("abort", s) : f.unsubscribe(s));
    }), e = null);
  };
  e.forEach((f) => f && f.addEventListener && f.addEventListener("abort", s));
  const { signal: a } = n;
  return a.unsubscribe = i, [a, () => {
    o && clearTimeout(o), o = null;
  }];
}, bs = ys, ws = function* (e, t) {
  let n = e.byteLength;
  if (!t || n < t) {
    yield e;
    return;
  }
  let r = 0, s;
  for (; r < n; )
    s = r + t, yield e.slice(r, s), r = s;
}, Ts = async function* (e, t, n) {
  for await (const r of e)
    yield* ws(ArrayBuffer.isView(r) ? r : await n(String(r)), t);
}, mt = (e, t, n, r, s) => {
  const o = Ts(e, t, s);
  let i = 0;
  return new ReadableStream({
    type: "bytes",
    async pull(a) {
      const { done: f, value: l } = await o.next();
      if (f) {
        a.close(), r();
        return;
      }
      let u = l.byteLength;
      n && n(i += u), a.enqueue(new Uint8Array(l));
    },
    cancel(a) {
      return r(a), o.return();
    }
  }, {
    highWaterMark: 2
  });
}, gt = (e, t) => {
  const n = e != null;
  return (r) => setTimeout(() => t({
    lengthComputable: n,
    total: e,
    loaded: r
  }));
}, Se = typeof fetch == "function" && typeof Request == "function" && typeof Response == "function", pn = Se && typeof ReadableStream == "function", Be = Se && (typeof TextEncoder == "function" ? ((e) => (t) => e.encode(t))(new TextEncoder()) : async (e) => new Uint8Array(await new Response(e).arrayBuffer())), Es = pn && (() => {
  let e = !1;
  const t = new Request(C.origin, {
    body: new ReadableStream(),
    method: "POST",
    get duplex() {
      return e = !0, "half";
    }
  }).headers.has("Content-Type");
  return e && !t;
})(), yt = 64 * 1024, Ie = pn && !!(() => {
  try {
    return c.isReadableStream(new Response("").body);
  } catch {
  }
})(), de = {
  stream: Ie && ((e) => e.body)
};
Se && ((e) => {
  ["text", "arrayBuffer", "blob", "formData", "stream"].forEach((t) => {
    !de[t] && (de[t] = c.isFunction(e[t]) ? (n) => n[t]() : (n, r) => {
      throw new w(`Response type '${t}' is not supported`, w.ERR_NOT_SUPPORT, r);
    });
  });
})(new Response());
const _s = async (e) => {
  if (e == null)
    return 0;
  if (c.isBlob(e))
    return e.size;
  if (c.isSpecCompliantForm(e))
    return (await new Request(e).arrayBuffer()).byteLength;
  if (c.isArrayBufferView(e))
    return e.byteLength;
  if (c.isURLSearchParams(e) && (e = e + ""), c.isString(e))
    return (await Be(e)).byteLength;
}, Ss = async (e, t) => {
  const n = c.toFiniteNumber(e.getContentLength());
  return n ?? _s(t);
}, As = Se && (async (e) => {
  let {
    url: t,
    method: n,
    data: r,
    signal: s,
    cancelToken: o,
    timeout: i,
    onDownloadProgress: a,
    onUploadProgress: f,
    responseType: l,
    headers: u,
    withCredentials: p = "same-origin",
    fetchOptions: b
  } = dn(e);
  l = l ? (l + "").toLowerCase() : "text";
  let [y, h] = s || o || i ? bs([s, o], i) : [], d, m;
  const g = () => {
    !d && setTimeout(() => {
      y && y.unsubscribe();
    }), d = !0;
  };
  let _;
  try {
    if (f && Es && n !== "get" && n !== "head" && (_ = await Ss(u, r)) !== 0) {
      let v = new Request(t, {
        method: "POST",
        body: r,
        duplex: "half"
      }), T;
      c.isFormData(r) && (T = v.headers.get("content-type")) && u.setContentType(T), v.body && (r = mt(v.body, yt, gt(
        _,
        fe(f)
      ), null, Be));
    }
    c.isString(p) || (p = p ? "cors" : "omit"), m = new Request(t, {
      ...b,
      signal: y,
      method: n.toUpperCase(),
      headers: u.normalize().toJSON(),
      body: r,
      duplex: "half",
      withCredentials: p
    });
    let E = await fetch(m);
    const $ = Ie && (l === "stream" || l === "response");
    if (Ie && (a || $)) {
      const v = {};
      ["status", "statusText", "headers"].forEach((P) => {
        v[P] = E[P];
      });
      const T = c.toFiniteNumber(E.headers.get("content-length"));
      E = new Response(
        mt(E.body, yt, a && gt(
          T,
          fe(a, !0)
        ), $ && g, Be),
        v
      );
    }
    l = l || "text";
    let O = await de[c.findKey(de, l) || "text"](E, e);
    return !$ && g(), h && h(), await new Promise((v, T) => {
      ln(v, T, {
        data: O,
        headers: j.from(E.headers),
        status: E.status,
        statusText: E.statusText,
        config: e,
        request: m
      });
    });
  } catch (E) {
    throw g(), E && E.name === "TypeError" && /fetch/i.test(E.message) ? Object.assign(
      new w("Network Error", w.ERR_NETWORK, e, m),
      {
        cause: E.cause || E
      }
    ) : w.from(E, E && E.code, e, m);
  }
}), Me = {
  http: Ir,
  xhr: gs,
  fetch: As
};
c.forEach(Me, (e, t) => {
  if (e) {
    try {
      Object.defineProperty(e, "name", { value: t });
    } catch {
    }
    Object.defineProperty(e, "adapterName", { value: t });
  }
});
const bt = (e) => `- ${e}`, Os = (e) => c.isFunction(e) || e === null || e === !1, hn = {
  getAdapter: (e) => {
    e = c.isArray(e) ? e : [e];
    const { length: t } = e;
    let n, r;
    const s = {};
    for (let o = 0; o < t; o++) {
      n = e[o];
      let i;
      if (r = n, !Os(n) && (r = Me[(i = String(n)).toLowerCase()], r === void 0))
        throw new w(`Unknown adapter '${i}'`);
      if (r)
        break;
      s[i || "#" + o] = r;
    }
    if (!r) {
      const o = Object.entries(s).map(
        ([a, f]) => `adapter ${a} ` + (f === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let i = t ? o.length > 1 ? `since :
` + o.map(bt).join(`
`) : " " + bt(o[0]) : "as no adapter specified";
      throw new w(
        "There is no suitable adapter to dispatch the request " + i,
        "ERR_NOT_SUPPORT"
      );
    }
    return r;
  },
  adapters: Me
};
function xe(e) {
  if (e.cancelToken && e.cancelToken.throwIfRequested(), e.signal && e.signal.aborted)
    throw new k(null, e);
}
function wt(e) {
  return xe(e), e.headers = j.from(e.headers), e.data = Pe.call(
    e,
    e.transformRequest
  ), ["post", "put", "patch"].indexOf(e.method) !== -1 && e.headers.setContentType("application/x-www-form-urlencoded", !1), hn.getAdapter(e.adapter || Ze.adapter)(e).then(function(r) {
    return xe(e), r.data = Pe.call(
      e,
      e.transformResponse,
      r
    ), r.headers = j.from(r.headers), r;
  }, function(r) {
    return un(r) || (xe(e), r && r.response && (r.response.data = Pe.call(
      e,
      e.transformResponse,
      r.response
    ), r.response.headers = j.from(r.response.headers))), Promise.reject(r);
  });
}
const mn = "1.7.2", Qe = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((e, t) => {
  Qe[e] = function(r) {
    return typeof r === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
const Tt = {};
Qe.transitional = function(t, n, r) {
  function s(o, i) {
    return "[Axios v" + mn + "] Transitional option '" + o + "'" + i + (r ? ". " + r : "");
  }
  return (o, i, a) => {
    if (t === !1)
      throw new w(
        s(i, " has been removed" + (n ? " in " + n : "")),
        w.ERR_DEPRECATED
      );
    return n && !Tt[i] && (Tt[i] = !0, console.warn(
      s(
        i,
        " has been deprecated since v" + n + " and will be removed in the near future"
      )
    )), t ? t(o, i, a) : !0;
  };
};
function Rs(e, t, n) {
  if (typeof e != "object")
    throw new w("options must be an object", w.ERR_BAD_OPTION_VALUE);
  const r = Object.keys(e);
  let s = r.length;
  for (; s-- > 0; ) {
    const o = r[s], i = t[o];
    if (i) {
      const a = e[o], f = a === void 0 || i(a, o, e);
      if (f !== !0)
        throw new w("option " + o + " must be " + f, w.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (n !== !0)
      throw new w("Unknown option " + o, w.ERR_BAD_OPTION);
  }
}
const He = {
  assertOptions: Rs,
  validators: Qe
}, U = He.validators;
class pe {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new dt(),
      response: new dt()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  async request(t, n) {
    try {
      return await this._request(t, n);
    } catch (r) {
      if (r instanceof Error) {
        let s;
        Error.captureStackTrace ? Error.captureStackTrace(s = {}) : s = new Error();
        const o = s.stack ? s.stack.replace(/^.+\n/, "") : "";
        try {
          r.stack ? o && !String(r.stack).endsWith(o.replace(/^.+\n.+\n/, "")) && (r.stack += `
` + o) : r.stack = o;
        } catch {
        }
      }
      throw r;
    }
  }
  _request(t, n) {
    typeof t == "string" ? (n = n || {}, n.url = t) : n = t || {}, n = M(this.defaults, n);
    const { transitional: r, paramsSerializer: s, headers: o } = n;
    r !== void 0 && He.assertOptions(r, {
      silentJSONParsing: U.transitional(U.boolean),
      forcedJSONParsing: U.transitional(U.boolean),
      clarifyTimeoutError: U.transitional(U.boolean)
    }, !1), s != null && (c.isFunction(s) ? n.paramsSerializer = {
      serialize: s
    } : He.assertOptions(s, {
      encode: U.function,
      serialize: U.function
    }, !0)), n.method = (n.method || this.defaults.method || "get").toLowerCase();
    let i = o && c.merge(
      o.common,
      o[n.method]
    );
    o && c.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (h) => {
        delete o[h];
      }
    ), n.headers = j.concat(i, o);
    const a = [];
    let f = !0;
    this.interceptors.request.forEach(function(d) {
      typeof d.runWhen == "function" && d.runWhen(n) === !1 || (f = f && d.synchronous, a.unshift(d.fulfilled, d.rejected));
    });
    const l = [];
    this.interceptors.response.forEach(function(d) {
      l.push(d.fulfilled, d.rejected);
    });
    let u, p = 0, b;
    if (!f) {
      const h = [wt.bind(this), void 0];
      for (h.unshift.apply(h, a), h.push.apply(h, l), b = h.length, u = Promise.resolve(n); p < b; )
        u = u.then(h[p++], h[p++]);
      return u;
    }
    b = a.length;
    let y = n;
    for (p = 0; p < b; ) {
      const h = a[p++], d = a[p++];
      try {
        y = h(y);
      } catch (m) {
        d.call(this, m);
        break;
      }
    }
    try {
      u = wt.call(this, y);
    } catch (h) {
      return Promise.reject(h);
    }
    for (p = 0, b = l.length; p < b; )
      u = u.then(l[p++], l[p++]);
    return u;
  }
  getUri(t) {
    t = M(this.defaults, t);
    const n = fn(t.baseURL, t.url);
    return on(n, t.params, t.paramsSerializer);
  }
}
c.forEach(["delete", "get", "head", "options"], function(t) {
  pe.prototype[t] = function(n, r) {
    return this.request(M(r || {}, {
      method: t,
      url: n,
      data: (r || {}).data
    }));
  };
});
c.forEach(["post", "put", "patch"], function(t) {
  function n(r) {
    return function(o, i, a) {
      return this.request(M(a || {}, {
        method: t,
        headers: r ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: o,
        data: i
      }));
    };
  }
  pe.prototype[t] = n(), pe.prototype[t + "Form"] = n(!0);
});
const ue = pe;
class et {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let n;
    this.promise = new Promise(function(o) {
      n = o;
    });
    const r = this;
    this.promise.then((s) => {
      if (!r._listeners)
        return;
      let o = r._listeners.length;
      for (; o-- > 0; )
        r._listeners[o](s);
      r._listeners = null;
    }), this.promise.then = (s) => {
      let o;
      const i = new Promise((a) => {
        r.subscribe(a), o = a;
      }).then(s);
      return i.cancel = function() {
        r.unsubscribe(o);
      }, i;
    }, t(function(o, i, a) {
      r.reason || (r.reason = new k(o, i, a), n(r.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const n = this._listeners.indexOf(t);
    n !== -1 && this._listeners.splice(n, 1);
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new et(function(s) {
        t = s;
      }),
      cancel: t
    };
  }
}
const $s = et;
function vs(e) {
  return function(n) {
    return e.apply(null, n);
  };
}
function Ps(e) {
  return c.isObject(e) && e.isAxiosError === !0;
}
const qe = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(qe).forEach(([e, t]) => {
  qe[t] = e;
});
const xs = qe;
function gn(e) {
  const t = new ue(e), n = Wt(ue.prototype.request, t);
  return c.extend(n, ue.prototype, t, { allOwnKeys: !0 }), c.extend(n, t, null, { allOwnKeys: !0 }), n.create = function(s) {
    return gn(M(e, s));
  }, n;
}
const R = gn(Ze);
R.Axios = ue;
R.CanceledError = k;
R.CancelToken = $s;
R.isCancel = un;
R.VERSION = mn;
R.toFormData = Ee;
R.AxiosError = w;
R.Cancel = R.CanceledError;
R.all = function(t) {
  return Promise.all(t);
};
R.spread = vs;
R.isAxiosError = Ps;
R.mergeConfig = M;
R.AxiosHeaders = j;
R.formToJSON = (e) => cn(c.isHTMLForm(e) ? new FormData(e) : e);
R.getAdapter = hn.getAdapter;
R.HttpStatusCode = xs;
R.default = R;
const Cs = R;
var js = typeof global == "object" && global && global.Object === Object && global;
const yn = js;
var Ns = typeof self == "object" && self && self.Object === Object && self, Ls = yn || Ns || Function("return this")();
const L = Ls;
var Ds = L.Symbol;
const B = Ds;
var bn = Object.prototype, Fs = bn.hasOwnProperty, Us = bn.toString, X = B ? B.toStringTag : void 0;
function Bs(e) {
  var t = Fs.call(e, X), n = e[X];
  try {
    e[X] = void 0;
    var r = !0;
  } catch {
  }
  var s = Us.call(e);
  return r && (t ? e[X] = n : delete e[X]), s;
}
var Is = Object.prototype, Ms = Is.toString;
function Hs(e) {
  return Ms.call(e);
}
var qs = "[object Null]", zs = "[object Undefined]", Et = B ? B.toStringTag : void 0;
function se(e) {
  return e == null ? e === void 0 ? zs : qs : Et && Et in Object(e) ? Bs(e) : Hs(e);
}
function H(e) {
  return e != null && typeof e == "object";
}
var Gs = Array.isArray;
const Q = Gs;
function oe(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Ks = "[object AsyncFunction]", Js = "[object Function]", Ws = "[object GeneratorFunction]", ks = "[object Proxy]";
function wn(e) {
  if (!oe(e))
    return !1;
  var t = se(e);
  return t == Js || t == Ws || t == Ks || t == ks;
}
var Vs = L["__core-js_shared__"];
const Ce = Vs;
var _t = function() {
  var e = /[^.]+$/.exec(Ce && Ce.keys && Ce.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Xs(e) {
  return !!_t && _t in e;
}
var Ys = Function.prototype, Zs = Ys.toString;
function z(e) {
  if (e != null) {
    try {
      return Zs.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Qs = /[\\^$.*+?()[\]{}|]/g, eo = /^\[object .+?Constructor\]$/, to = Function.prototype, no = Object.prototype, ro = to.toString, so = no.hasOwnProperty, oo = RegExp(
  "^" + ro.call(so).replace(Qs, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function io(e) {
  if (!oe(e) || Xs(e))
    return !1;
  var t = wn(e) ? oo : eo;
  return t.test(z(e));
}
function ao(e, t) {
  return e == null ? void 0 : e[t];
}
function G(e, t) {
  var n = ao(e, t);
  return io(n) ? n : void 0;
}
var co = G(L, "WeakMap");
const ze = co;
var St = Object.create, uo = function() {
  function e() {
  }
  return function(t) {
    if (!oe(t))
      return {};
    if (St)
      return St(t);
    e.prototype = t;
    var n = new e();
    return e.prototype = void 0, n;
  };
}();
const lo = uo;
function fo(e, t) {
  var n = -1, r = e.length;
  for (t || (t = Array(r)); ++n < r; )
    t[n] = e[n];
  return t;
}
var po = function() {
  try {
    var e = G(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}();
const At = po;
function ho(e, t) {
  for (var n = -1, r = e == null ? 0 : e.length; ++n < r && t(e[n], n, e) !== !1; )
    ;
  return e;
}
var mo = 9007199254740991, go = /^(?:0|[1-9]\d*)$/;
function yo(e, t) {
  var n = typeof e;
  return t = t ?? mo, !!t && (n == "number" || n != "symbol" && go.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function Tn(e, t, n) {
  t == "__proto__" && At ? At(e, t, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : e[t] = n;
}
function tt(e, t) {
  return e === t || e !== e && t !== t;
}
var bo = Object.prototype, wo = bo.hasOwnProperty;
function En(e, t, n) {
  var r = e[t];
  (!(wo.call(e, t) && tt(r, n)) || n === void 0 && !(t in e)) && Tn(e, t, n);
}
function Ae(e, t, n, r) {
  var s = !n;
  n || (n = {});
  for (var o = -1, i = t.length; ++o < i; ) {
    var a = t[o], f = r ? r(n[a], e[a], a, n, e) : void 0;
    f === void 0 && (f = e[a]), s ? Tn(n, a, f) : En(n, a, f);
  }
  return n;
}
var To = 9007199254740991;
function _n(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= To;
}
function Sn(e) {
  return e != null && _n(e.length) && !wn(e);
}
var Eo = Object.prototype;
function nt(e) {
  var t = e && e.constructor, n = typeof t == "function" && t.prototype || Eo;
  return e === n;
}
function _o(e, t) {
  for (var n = -1, r = Array(e); ++n < e; )
    r[n] = t(n);
  return r;
}
var So = "[object Arguments]";
function Ot(e) {
  return H(e) && se(e) == So;
}
var An = Object.prototype, Ao = An.hasOwnProperty, Oo = An.propertyIsEnumerable, Ro = Ot(function() {
  return arguments;
}()) ? Ot : function(e) {
  return H(e) && Ao.call(e, "callee") && !Oo.call(e, "callee");
};
const $o = Ro;
function vo() {
  return !1;
}
var On = typeof exports == "object" && exports && !exports.nodeType && exports, Rt = On && typeof module == "object" && module && !module.nodeType && module, Po = Rt && Rt.exports === On, $t = Po ? L.Buffer : void 0, xo = $t ? $t.isBuffer : void 0, Co = xo || vo;
const he = Co;
var jo = "[object Arguments]", No = "[object Array]", Lo = "[object Boolean]", Do = "[object Date]", Fo = "[object Error]", Uo = "[object Function]", Bo = "[object Map]", Io = "[object Number]", Mo = "[object Object]", Ho = "[object RegExp]", qo = "[object Set]", zo = "[object String]", Go = "[object WeakMap]", Ko = "[object ArrayBuffer]", Jo = "[object DataView]", Wo = "[object Float32Array]", ko = "[object Float64Array]", Vo = "[object Int8Array]", Xo = "[object Int16Array]", Yo = "[object Int32Array]", Zo = "[object Uint8Array]", Qo = "[object Uint8ClampedArray]", ei = "[object Uint16Array]", ti = "[object Uint32Array]", A = {};
A[Wo] = A[ko] = A[Vo] = A[Xo] = A[Yo] = A[Zo] = A[Qo] = A[ei] = A[ti] = !0;
A[jo] = A[No] = A[Ko] = A[Lo] = A[Jo] = A[Do] = A[Fo] = A[Uo] = A[Bo] = A[Io] = A[Mo] = A[Ho] = A[qo] = A[zo] = A[Go] = !1;
function ni(e) {
  return H(e) && _n(e.length) && !!A[se(e)];
}
function rt(e) {
  return function(t) {
    return e(t);
  };
}
var Rn = typeof exports == "object" && exports && !exports.nodeType && exports, Y = Rn && typeof module == "object" && module && !module.nodeType && module, ri = Y && Y.exports === Rn, je = ri && yn.process, si = function() {
  try {
    var e = Y && Y.require && Y.require("util").types;
    return e || je && je.binding && je.binding("util");
  } catch {
  }
}();
const J = si;
var vt = J && J.isTypedArray, oi = vt ? rt(vt) : ni;
const $n = oi;
var ii = Object.prototype, ai = ii.hasOwnProperty;
function vn(e, t) {
  var n = Q(e), r = !n && $o(e), s = !n && !r && he(e), o = !n && !r && !s && $n(e), i = n || r || s || o, a = i ? _o(e.length, String) : [], f = a.length;
  for (var l in e)
    (t || ai.call(e, l)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    s && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    o && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    yo(l, f))) && a.push(l);
  return a;
}
function Pn(e, t) {
  return function(n) {
    return e(t(n));
  };
}
var ci = Pn(Object.keys, Object);
const ui = ci;
var li = Object.prototype, fi = li.hasOwnProperty;
function di(e) {
  if (!nt(e))
    return ui(e);
  var t = [];
  for (var n in Object(e))
    fi.call(e, n) && n != "constructor" && t.push(n);
  return t;
}
function st(e) {
  return Sn(e) ? vn(e) : di(e);
}
function pi(e) {
  var t = [];
  if (e != null)
    for (var n in Object(e))
      t.push(n);
  return t;
}
var hi = Object.prototype, mi = hi.hasOwnProperty;
function gi(e) {
  if (!oe(e))
    return pi(e);
  var t = nt(e), n = [];
  for (var r in e)
    r == "constructor" && (t || !mi.call(e, r)) || n.push(r);
  return n;
}
function ot(e) {
  return Sn(e) ? vn(e, !0) : gi(e);
}
var yi = G(Object, "create");
const ee = yi;
function bi() {
  this.__data__ = ee ? ee(null) : {}, this.size = 0;
}
function wi(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Ti = "__lodash_hash_undefined__", Ei = Object.prototype, _i = Ei.hasOwnProperty;
function Si(e) {
  var t = this.__data__;
  if (ee) {
    var n = t[e];
    return n === Ti ? void 0 : n;
  }
  return _i.call(t, e) ? t[e] : void 0;
}
var Ai = Object.prototype, Oi = Ai.hasOwnProperty;
function Ri(e) {
  var t = this.__data__;
  return ee ? t[e] !== void 0 : Oi.call(t, e);
}
var $i = "__lodash_hash_undefined__";
function vi(e, t) {
  var n = this.__data__;
  return this.size += this.has(e) ? 0 : 1, n[e] = ee && t === void 0 ? $i : t, this;
}
function q(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var r = e[t];
    this.set(r[0], r[1]);
  }
}
q.prototype.clear = bi;
q.prototype.delete = wi;
q.prototype.get = Si;
q.prototype.has = Ri;
q.prototype.set = vi;
function Pi() {
  this.__data__ = [], this.size = 0;
}
function Oe(e, t) {
  for (var n = e.length; n--; )
    if (tt(e[n][0], t))
      return n;
  return -1;
}
var xi = Array.prototype, Ci = xi.splice;
function ji(e) {
  var t = this.__data__, n = Oe(t, e);
  if (n < 0)
    return !1;
  var r = t.length - 1;
  return n == r ? t.pop() : Ci.call(t, n, 1), --this.size, !0;
}
function Ni(e) {
  var t = this.__data__, n = Oe(t, e);
  return n < 0 ? void 0 : t[n][1];
}
function Li(e) {
  return Oe(this.__data__, e) > -1;
}
function Di(e, t) {
  var n = this.__data__, r = Oe(n, e);
  return r < 0 ? (++this.size, n.push([e, t])) : n[r][1] = t, this;
}
function F(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var r = e[t];
    this.set(r[0], r[1]);
  }
}
F.prototype.clear = Pi;
F.prototype.delete = ji;
F.prototype.get = Ni;
F.prototype.has = Li;
F.prototype.set = Di;
var Fi = G(L, "Map");
const te = Fi;
function Ui() {
  this.size = 0, this.__data__ = {
    hash: new q(),
    map: new (te || F)(),
    string: new q()
  };
}
function Bi(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function Re(e, t) {
  var n = e.__data__;
  return Bi(t) ? n[typeof t == "string" ? "string" : "hash"] : n.map;
}
function Ii(e) {
  var t = Re(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function Mi(e) {
  return Re(this, e).get(e);
}
function Hi(e) {
  return Re(this, e).has(e);
}
function qi(e, t) {
  var n = Re(this, e), r = n.size;
  return n.set(e, t), this.size += n.size == r ? 0 : 1, this;
}
function K(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var r = e[t];
    this.set(r[0], r[1]);
  }
}
K.prototype.clear = Ui;
K.prototype.delete = Ii;
K.prototype.get = Mi;
K.prototype.has = Hi;
K.prototype.set = qi;
function xn(e, t) {
  for (var n = -1, r = t.length, s = e.length; ++n < r; )
    e[s + n] = t[n];
  return e;
}
var zi = Pn(Object.getPrototypeOf, Object);
const Cn = zi;
function Gi() {
  this.__data__ = new F(), this.size = 0;
}
function Ki(e) {
  var t = this.__data__, n = t.delete(e);
  return this.size = t.size, n;
}
function Ji(e) {
  return this.__data__.get(e);
}
function Wi(e) {
  return this.__data__.has(e);
}
var ki = 200;
function Vi(e, t) {
  var n = this.__data__;
  if (n instanceof F) {
    var r = n.__data__;
    if (!te || r.length < ki - 1)
      return r.push([e, t]), this.size = ++n.size, this;
    n = this.__data__ = new K(r);
  }
  return n.set(e, t), this.size = n.size, this;
}
function D(e) {
  var t = this.__data__ = new F(e);
  this.size = t.size;
}
D.prototype.clear = Gi;
D.prototype.delete = Ki;
D.prototype.get = Ji;
D.prototype.has = Wi;
D.prototype.set = Vi;
function Xi(e, t) {
  return e && Ae(t, st(t), e);
}
function Yi(e, t) {
  return e && Ae(t, ot(t), e);
}
var jn = typeof exports == "object" && exports && !exports.nodeType && exports, Pt = jn && typeof module == "object" && module && !module.nodeType && module, Zi = Pt && Pt.exports === jn, xt = Zi ? L.Buffer : void 0, Ct = xt ? xt.allocUnsafe : void 0;
function Qi(e, t) {
  if (t)
    return e.slice();
  var n = e.length, r = Ct ? Ct(n) : new e.constructor(n);
  return e.copy(r), r;
}
function ea(e, t) {
  for (var n = -1, r = e == null ? 0 : e.length, s = 0, o = []; ++n < r; ) {
    var i = e[n];
    t(i, n, e) && (o[s++] = i);
  }
  return o;
}
function Nn() {
  return [];
}
var ta = Object.prototype, na = ta.propertyIsEnumerable, jt = Object.getOwnPropertySymbols, ra = jt ? function(e) {
  return e == null ? [] : (e = Object(e), ea(jt(e), function(t) {
    return na.call(e, t);
  }));
} : Nn;
const it = ra;
function sa(e, t) {
  return Ae(e, it(e), t);
}
var oa = Object.getOwnPropertySymbols, ia = oa ? function(e) {
  for (var t = []; e; )
    xn(t, it(e)), e = Cn(e);
  return t;
} : Nn;
const Ln = ia;
function aa(e, t) {
  return Ae(e, Ln(e), t);
}
function Dn(e, t, n) {
  var r = t(e);
  return Q(e) ? r : xn(r, n(e));
}
function Ge(e) {
  return Dn(e, st, it);
}
function ca(e) {
  return Dn(e, ot, Ln);
}
var ua = G(L, "DataView");
const Ke = ua;
var la = G(L, "Promise");
const Je = la;
var fa = G(L, "Set");
const We = fa;
var Nt = "[object Map]", da = "[object Object]", Lt = "[object Promise]", Dt = "[object Set]", Ft = "[object WeakMap]", Ut = "[object DataView]", pa = z(Ke), ha = z(te), ma = z(Je), ga = z(We), ya = z(ze), I = se;
(Ke && I(new Ke(new ArrayBuffer(1))) != Ut || te && I(new te()) != Nt || Je && I(Je.resolve()) != Lt || We && I(new We()) != Dt || ze && I(new ze()) != Ft) && (I = function(e) {
  var t = se(e), n = t == da ? e.constructor : void 0, r = n ? z(n) : "";
  if (r)
    switch (r) {
      case pa:
        return Ut;
      case ha:
        return Nt;
      case ma:
        return Lt;
      case ga:
        return Dt;
      case ya:
        return Ft;
    }
  return t;
});
const ne = I;
var ba = Object.prototype, wa = ba.hasOwnProperty;
function Ta(e) {
  var t = e.length, n = new e.constructor(t);
  return t && typeof e[0] == "string" && wa.call(e, "index") && (n.index = e.index, n.input = e.input), n;
}
var Ea = L.Uint8Array;
const me = Ea;
function at(e) {
  var t = new e.constructor(e.byteLength);
  return new me(t).set(new me(e)), t;
}
function _a(e, t) {
  var n = t ? at(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.byteLength);
}
var Sa = /\w*$/;
function Aa(e) {
  var t = new e.constructor(e.source, Sa.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Bt = B ? B.prototype : void 0, It = Bt ? Bt.valueOf : void 0;
function Oa(e) {
  return It ? Object(It.call(e)) : {};
}
function Ra(e, t) {
  var n = t ? at(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.length);
}
var $a = "[object Boolean]", va = "[object Date]", Pa = "[object Map]", xa = "[object Number]", Ca = "[object RegExp]", ja = "[object Set]", Na = "[object String]", La = "[object Symbol]", Da = "[object ArrayBuffer]", Fa = "[object DataView]", Ua = "[object Float32Array]", Ba = "[object Float64Array]", Ia = "[object Int8Array]", Ma = "[object Int16Array]", Ha = "[object Int32Array]", qa = "[object Uint8Array]", za = "[object Uint8ClampedArray]", Ga = "[object Uint16Array]", Ka = "[object Uint32Array]";
function Ja(e, t, n) {
  var r = e.constructor;
  switch (t) {
    case Da:
      return at(e);
    case $a:
    case va:
      return new r(+e);
    case Fa:
      return _a(e, n);
    case Ua:
    case Ba:
    case Ia:
    case Ma:
    case Ha:
    case qa:
    case za:
    case Ga:
    case Ka:
      return Ra(e, n);
    case Pa:
      return new r();
    case xa:
    case Na:
      return new r(e);
    case Ca:
      return Aa(e);
    case ja:
      return new r();
    case La:
      return Oa(e);
  }
}
function Wa(e) {
  return typeof e.constructor == "function" && !nt(e) ? lo(Cn(e)) : {};
}
var ka = "[object Map]";
function Va(e) {
  return H(e) && ne(e) == ka;
}
var Mt = J && J.isMap, Xa = Mt ? rt(Mt) : Va;
const Ya = Xa;
var Za = "[object Set]";
function Qa(e) {
  return H(e) && ne(e) == Za;
}
var Ht = J && J.isSet, ec = Ht ? rt(Ht) : Qa;
const tc = ec;
var nc = 1, rc = 2, sc = 4, Fn = "[object Arguments]", oc = "[object Array]", ic = "[object Boolean]", ac = "[object Date]", cc = "[object Error]", Un = "[object Function]", uc = "[object GeneratorFunction]", lc = "[object Map]", fc = "[object Number]", Bn = "[object Object]", dc = "[object RegExp]", pc = "[object Set]", hc = "[object String]", mc = "[object Symbol]", gc = "[object WeakMap]", yc = "[object ArrayBuffer]", bc = "[object DataView]", wc = "[object Float32Array]", Tc = "[object Float64Array]", Ec = "[object Int8Array]", _c = "[object Int16Array]", Sc = "[object Int32Array]", Ac = "[object Uint8Array]", Oc = "[object Uint8ClampedArray]", Rc = "[object Uint16Array]", $c = "[object Uint32Array]", S = {};
S[Fn] = S[oc] = S[yc] = S[bc] = S[ic] = S[ac] = S[wc] = S[Tc] = S[Ec] = S[_c] = S[Sc] = S[lc] = S[fc] = S[Bn] = S[dc] = S[pc] = S[hc] = S[mc] = S[Ac] = S[Oc] = S[Rc] = S[$c] = !0;
S[cc] = S[Un] = S[gc] = !1;
function le(e, t, n, r, s, o) {
  var i, a = t & nc, f = t & rc, l = t & sc;
  if (n && (i = s ? n(e, r, s, o) : n(e)), i !== void 0)
    return i;
  if (!oe(e))
    return e;
  var u = Q(e);
  if (u) {
    if (i = Ta(e), !a)
      return fo(e, i);
  } else {
    var p = ne(e), b = p == Un || p == uc;
    if (he(e))
      return Qi(e, a);
    if (p == Bn || p == Fn || b && !s) {
      if (i = f || b ? {} : Wa(e), !a)
        return f ? aa(e, Yi(i, e)) : sa(e, Xi(i, e));
    } else {
      if (!S[p])
        return s ? e : {};
      i = Ja(e, p, a);
    }
  }
  o || (o = new D());
  var y = o.get(e);
  if (y)
    return y;
  o.set(e, i), tc(e) ? e.forEach(function(m) {
    i.add(le(m, t, n, m, e, o));
  }) : Ya(e) && e.forEach(function(m, g) {
    i.set(g, le(m, t, n, g, e, o));
  });
  var h = l ? f ? ca : Ge : f ? ot : st, d = u ? void 0 : h(e);
  return ho(d || e, function(m, g) {
    d && (g = m, m = e[g]), En(i, g, le(m, t, n, g, e, o));
  }), i;
}
var vc = 1, Pc = 4;
function Ne(e) {
  return le(e, vc | Pc);
}
var xc = "__lodash_hash_undefined__";
function Cc(e) {
  return this.__data__.set(e, xc), this;
}
function jc(e) {
  return this.__data__.has(e);
}
function ge(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.__data__ = new K(); ++t < n; )
    this.add(e[t]);
}
ge.prototype.add = ge.prototype.push = Cc;
ge.prototype.has = jc;
function Nc(e, t) {
  for (var n = -1, r = e == null ? 0 : e.length; ++n < r; )
    if (t(e[n], n, e))
      return !0;
  return !1;
}
function Lc(e, t) {
  return e.has(t);
}
var Dc = 1, Fc = 2;
function In(e, t, n, r, s, o) {
  var i = n & Dc, a = e.length, f = t.length;
  if (a != f && !(i && f > a))
    return !1;
  var l = o.get(e), u = o.get(t);
  if (l && u)
    return l == t && u == e;
  var p = -1, b = !0, y = n & Fc ? new ge() : void 0;
  for (o.set(e, t), o.set(t, e); ++p < a; ) {
    var h = e[p], d = t[p];
    if (r)
      var m = i ? r(d, h, p, t, e, o) : r(h, d, p, e, t, o);
    if (m !== void 0) {
      if (m)
        continue;
      b = !1;
      break;
    }
    if (y) {
      if (!Nc(t, function(g, _) {
        if (!Lc(y, _) && (h === g || s(h, g, n, r, o)))
          return y.push(_);
      })) {
        b = !1;
        break;
      }
    } else if (!(h === d || s(h, d, n, r, o))) {
      b = !1;
      break;
    }
  }
  return o.delete(e), o.delete(t), b;
}
function Uc(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(r, s) {
    n[++t] = [s, r];
  }), n;
}
function Bc(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(r) {
    n[++t] = r;
  }), n;
}
var Ic = 1, Mc = 2, Hc = "[object Boolean]", qc = "[object Date]", zc = "[object Error]", Gc = "[object Map]", Kc = "[object Number]", Jc = "[object RegExp]", Wc = "[object Set]", kc = "[object String]", Vc = "[object Symbol]", Xc = "[object ArrayBuffer]", Yc = "[object DataView]", qt = B ? B.prototype : void 0, Le = qt ? qt.valueOf : void 0;
function Zc(e, t, n, r, s, o, i) {
  switch (n) {
    case Yc:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case Xc:
      return !(e.byteLength != t.byteLength || !o(new me(e), new me(t)));
    case Hc:
    case qc:
    case Kc:
      return tt(+e, +t);
    case zc:
      return e.name == t.name && e.message == t.message;
    case Jc:
    case kc:
      return e == t + "";
    case Gc:
      var a = Uc;
    case Wc:
      var f = r & Ic;
      if (a || (a = Bc), e.size != t.size && !f)
        return !1;
      var l = i.get(e);
      if (l)
        return l == t;
      r |= Mc, i.set(e, t);
      var u = In(a(e), a(t), r, s, o, i);
      return i.delete(e), u;
    case Vc:
      if (Le)
        return Le.call(e) == Le.call(t);
  }
  return !1;
}
var Qc = 1, eu = Object.prototype, tu = eu.hasOwnProperty;
function nu(e, t, n, r, s, o) {
  var i = n & Qc, a = Ge(e), f = a.length, l = Ge(t), u = l.length;
  if (f != u && !i)
    return !1;
  for (var p = f; p--; ) {
    var b = a[p];
    if (!(i ? b in t : tu.call(t, b)))
      return !1;
  }
  var y = o.get(e), h = o.get(t);
  if (y && h)
    return y == t && h == e;
  var d = !0;
  o.set(e, t), o.set(t, e);
  for (var m = i; ++p < f; ) {
    b = a[p];
    var g = e[b], _ = t[b];
    if (r)
      var E = i ? r(_, g, b, t, e, o) : r(g, _, b, e, t, o);
    if (!(E === void 0 ? g === _ || s(g, _, n, r, o) : E)) {
      d = !1;
      break;
    }
    m || (m = b == "constructor");
  }
  if (d && !m) {
    var $ = e.constructor, O = t.constructor;
    $ != O && "constructor" in e && "constructor" in t && !(typeof $ == "function" && $ instanceof $ && typeof O == "function" && O instanceof O) && (d = !1);
  }
  return o.delete(e), o.delete(t), d;
}
var ru = 1, zt = "[object Arguments]", Gt = "[object Array]", ie = "[object Object]", su = Object.prototype, Kt = su.hasOwnProperty;
function ou(e, t, n, r, s, o) {
  var i = Q(e), a = Q(t), f = i ? Gt : ne(e), l = a ? Gt : ne(t);
  f = f == zt ? ie : f, l = l == zt ? ie : l;
  var u = f == ie, p = l == ie, b = f == l;
  if (b && he(e)) {
    if (!he(t))
      return !1;
    i = !0, u = !1;
  }
  if (b && !u)
    return o || (o = new D()), i || $n(e) ? In(e, t, n, r, s, o) : Zc(e, t, f, n, r, s, o);
  if (!(n & ru)) {
    var y = u && Kt.call(e, "__wrapped__"), h = p && Kt.call(t, "__wrapped__");
    if (y || h) {
      var d = y ? e.value() : e, m = h ? t.value() : t;
      return o || (o = new D()), s(d, m, n, r, o);
    }
  }
  return b ? (o || (o = new D()), nu(e, t, n, r, s, o)) : !1;
}
function Mn(e, t, n, r, s) {
  return e === t ? !0 : e == null || t == null || !H(e) && !H(t) ? e !== e && t !== t : ou(e, t, n, r, Mn, s);
}
function iu(e, t) {
  return Mn(e, t);
}
function De(e) {
  return typeof e == "function" ? e() : Vn(e);
}
const au = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const cu = () => {
};
function Hn(e) {
  return e || Xn();
}
function uu(e, t = !0, n) {
  Hn() ? Jn(e, n) : t ? e() : Wn(e);
}
function lu(e, t) {
  Hn(t) && kn(e, t);
}
const fu = au ? window.document : void 0;
function du(e, t = cu, n = {}) {
  const {
    immediate: r = !0,
    manual: s = !1,
    type: o = "text/javascript",
    async: i = !0,
    crossOrigin: a,
    referrerPolicy: f,
    noModule: l,
    defer: u,
    document: p = fu,
    attrs: b = {}
  } = n, y = Yn(null);
  let h = null;
  const d = (_) => new Promise((E, $) => {
    const O = (P) => (y.value = P, E(P), P);
    if (!p) {
      E(!1);
      return;
    }
    let v = !1, T = p.querySelector(`script[src="${De(e)}"]`);
    T ? T.hasAttribute("data-loaded") && O(T) : (T = p.createElement("script"), T.type = o, T.async = i, T.src = De(e), u && (T.defer = u), a && (T.crossOrigin = a), l && (T.noModule = l), f && (T.referrerPolicy = f), Object.entries(b).forEach(([P, qn]) => T == null ? void 0 : T.setAttribute(P, qn)), v = !0), T.addEventListener("error", (P) => $(P)), T.addEventListener("abort", (P) => $(P)), T.addEventListener("load", () => {
      T.setAttribute("data-loaded", "true"), t(T), O(T);
    }), v && (T = p.head.appendChild(T)), _ || O(T);
  }), m = (_ = !0) => (h || (h = d(_)), h), g = () => {
    if (!p)
      return;
    h = null, y.value && (y.value = null);
    const _ = p.querySelector(`script[src="${De(e)}"]`);
    _ && p.head.removeChild(_);
  };
  return r && !s && uu(m), s || lu(g), { scriptTag: y, load: m, unload: g };
}
const pu = () => new Promise((e, t) => {
  var s, o;
  if (typeof window > "u" || !window.grecaptcha)
    return t("Germinal: Recaptcha not found on window");
  const r = (o = ((s = ye().props) == null ? void 0 : s.modules).cf7) == null ? void 0 : o.recaptchaSiteKey;
  if (!r)
    return t("Germinal: Not Recaptcha site key set in CF7");
  window.grecaptcha.ready(function() {
    var i;
    (i = window.grecaptcha) == null || i.execute(r, { action: "submit" }).then(function(a) {
      e(a);
    });
  });
}), wu = (e = {}, t = {}) => {
  var b, y, h;
  const { immediate: n = !1, redirect: r, formId: s = "contact" } = t;
  let o = Ne(e), i, a = (d) => d;
  const f = (b = ye().props) == null ? void 0 : b.modules;
  (y = f == null ? void 0 : f.cf7) != null && y.recaptchaUrl || console.warn("Germinal: No recaptcha URL available");
  const { load: l, unload: u } = (h = f == null ? void 0 : f.cf7) != null && h.recaptchaUrl ? du(
    f.cf7.recaptchaUrl,
    // on script tag loaded.
    void 0,
    {
      manual: n === !1
    }
  ) : { load: () => {
  }, unload: () => {
  } }, p = Zn({
    ...e,
    errors: {},
    formMessage: "",
    isDirty: !1,
    hasErrors: !1,
    processing: !1,
    progress: null,
    wasSuccessful: !1,
    recentlySuccessful: !1,
    loadRecaptcha: l,
    unloadRecaptcha: () => {
      document.querySelectorAll(".grecaptcha-badge").forEach((m) => {
        m != null && m.parentElement && m.parentElement.remove();
      }), window.grecaptcha && delete window.grecaptcha, window.___grecaptcha_cfg && delete window.___grecaptcha_cfg, u();
    },
    data() {
      return Object.keys(e).reduce((d, m) => (d[m] = this[m], d), {});
    },
    transform(d) {
      return a = d, this;
    },
    defaults(d, m) {
      return typeof d > "u" ? o = this.data() : o = Object.assign(
        {},
        Ne(o),
        typeof d == "string" ? { [d]: m } : d
      ), this;
    },
    reset(...d) {
      let m = Ne(o);
      return d.length === 0 ? Object.assign(this, m) : Object.assign(
        this,
        Object.keys(m).filter((g) => d.includes(g)).reduce((g, _) => (g[_] = m[_], g), {})
      ), this;
    },
    setError(d, m) {
      return Object.assign(
        this.errors,
        typeof d == "string" ? { [d]: m } : d
      ), this.hasErrors = Object.keys(this.errors).length > 0, this;
    },
    clearErrors(...d) {
      return this.errors = Object.keys(this.errors).reduce(
        (m, g) => ({
          ...m,
          ...d.length > 0 && !d.includes(g) ? { [g]: this.errors[g] } : {}
        }),
        {}
      ), this.hasErrors = Object.keys(this.errors).length > 0, this;
    },
    async submit(d = "post", m, { headers: g = {}, ..._ } = {}) {
      const E = a(this.data());
      this.processing = !0, this.wasSuccessful = !1, this.recentlySuccessful = !1, i && clearTimeout(i);
      const $ = new FormData();
      return $.append("_wpcf7_recaptcha_response", await pu()), $.append("_wpcf7_unit_tag", `wpcf7-f-${s}-123`), Object.keys(E).forEach((O) => {
        $.append(O, E[O]);
      }), Cs({
        method: d,
        url: m,
        data: $,
        headers: g,
        ..._
      }).then((O) => {
        if (O.data.message && (this.formMessage = O.data.message), O.data.status === "validation_failed") {
          const v = O.data.invalid_fields.reduce(
            (T, P) => (T[P.field] = P.message, T),
            {}
          );
          this.clearErrors().setError(v), this.formMessage = "There were errors with parts of this form";
        } else if (O.data.status === "spam") {
          const v = { recaptcha: "failed" };
          this.setError(v), this.formMessage = "There was a problem submitting this form";
        } else
          this.clearErrors(), this.wasSuccessful = !0, this.recentlySuccessful = !0, i = window.setTimeout(() => this.recentlySuccessful = !1, 2e3), this.reset(), this.isDirty = !1, r && er.get(r);
      }).catch((O) => {
        console.log(O);
      }).finally(() => {
        this.processing = !1, this.progress = null;
      });
    },
    get(d, m) {
      return this.submit("get", d, m);
    },
    post(d, m) {
      return this.submit("post", d, m);
    },
    patch(d, m) {
      return this.submit("patch", d, m);
    },
    put(d, m) {
      return this.submit("put", d, m);
    },
    delete(d, m) {
      return this.submit("delete", d, m);
    }
  });
  return Qn(
    p,
    () => {
      p.isDirty = !iu(p.data(), o);
    },
    { immediate: !0, deep: !0 }
  ), p;
}, Tu = (e) => Jt(() => {
  var s;
  const t = Array.isArray(e) ? e : [e], n = (s = ye().props) == null ? void 0 : s.sitePreferences, r = (o) => {
    var i;
    return ((i = n == null ? void 0 : n.find((a) => a.slug === o)) == null ? void 0 : i.value) || null;
  };
  return t.reduce((o, i) => (o[i] = r(i), o), {});
}), hu = (e) => {
  const [t, n] = e.split("?"), r = {};
  return n && n.split("&").forEach((s) => {
    const [o, i] = s.split("=");
    r[o] = i;
  }), {
    pageName: t,
    params: r
  };
};
async function Eu(e) {
  const {
    name: t,
    pages: n,
    templates: r,
    pageRoot: s = "./pages",
    pageExtension: o = "vue",
    templateRoot: i = "./templates",
    defaultTemplate: a = "Default",
    templateExtension: f = "vue",
    templateWrap: l
  } = e, { pageName: u, params: p } = hu(t), b = `${s}/${u}.${o}`, y = n[b];
  if (!y)
    throw new Error(`Unable to find page ${s}/${t}.${o}`);
  const h = typeof y == "function" ? await y() : await y;
  if (p.template) {
    const d = `${i}/${p.template}.${f}`, m = r[d], g = typeof m == "function" ? await m() : await m;
    h.default.layout = l ? [l, g.default] : g.default;
  } else if (h.default.layout) {
    if (l) {
      const d = Array.isArray(h.default.layout) ? h.default.layout.filter((m) => m.__name !== "TemplateWrap") : [h.default.layout];
      h.default.layout = [l, ...d];
    }
  } else {
    const d = `${i}/${a}.${f}`, m = r[d], g = typeof m == "function" ? await m() : await m;
    h.default.layout = l ? [l, g.default] : g.default;
  }
  if (typeof h > "u")
    throw new Error(`Page not found: ${b}`);
  return h;
}
export {
  yu as Test,
  Eu as resolvePageComponent,
  bu as useAcf,
  wu as useCf7Form,
  Tu as useSitePreferences
};
