import { ref, computed, watch, isReadonly, isRef, unref } from "vue";
import { usePage } from "@inertiajs/vue3";
import axios from "axios";

export const useSearch = (index, limit = 10, filter = []) => {
	const meilisearch = computed(() => usePage().props.modules.meilisearch);
	const indexName = computed(() => meilisearch.value.indexes[index]);
	const url = computed(() => `https://${meilisearch.value.host}/indexes/${indexName.value}/search`);
	let query = ref("");
	let results = ref([]);
	let isSearching = ref(false);
	let estimatedServerTotal = ref(0);
	const total = computed(() => results.value.length);
	const isTruncated = computed(() => estimatedServerTotal.value > total.value);
	const hasResults = computed(() => total.value > 0);
	let signals = [];

	const doSearch = (q) => {
		if (!q) {
			results.value = [];
			estimatedServerTotal.value = 0;
			return;
		}
		if (!url.value) return;

		isSearching.value = true;
		while (signals.length) {
			const c = signals.shift();
			c.abort();
		}

		const controller = new AbortController();
		signals.push(controller);
		axios
			.post(
				url.value,
				{
					q,
					limit,
					attributesToHighlight: ["content", "tags", "title", "categories"],
					highlightPreTag: `<span class="before:block before:absolute before:-inset-0.5 before:-skew-y-3 before:bg-pink-500 relative inline-block">
                            <span class="text-white relative font-bold">`,
					highlightPostTag: "</span></span>",
					showMatchesPosition: true,
					attributesToCrop: ["content"],
					filter: isRef(filter) || isReadonly(filter) ? filter.value : filter,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${meilisearch.value.api_key}`,
					},
					signal: controller.signal,
				}
			)
			.then((res) => {
				results.value = res.data.hits;
				estimatedServerTotal.value = res.data.estimatedTotalHits;
			})
			.catch((e) => {
				if (e.code !== "ERR_CANCELED") throw e;
			})
			.finally(() => {
				isSearching.value = false;
			});
	};

	watch(query, doSearch);

	return {
		query,
		results,
		isSearching,
		estimatedServerTotal,
		total,
		isTruncated,
		hasResults,
	};
};
