import { onMounted, ref } from "vue";

const scrollType = ref("mouse");

let isTrackpadDefined = false;
let eventCount = 0;
let eventCountStart;

const detectTrackpad = () => {
	if (eventCount === 0) {
		eventCountStart = performance.now();
	}

	eventCount++;

	if (performance.now() - eventCountStart > 66) {
		if (eventCount > 5) {
			scrollType.value = "trackpad";
			isTrackpadDefined = true;
		} else {
			scrollType.value = "mouse";
			isTrackpadDefined = true;
		}
	}

	if (isTrackpadDefined === true) {
		document.removeEventListener("wheel", detectTrackpad, false);
	}
};

export const useScrollType = () => {
	onMounted(() => {
		if (typeof window !== "undefined") {
			document.addEventListener("wheel", detectTrackpad, false);
		}
	});
	return scrollType;
};
