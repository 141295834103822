<template>
	<button
		v-ripple
		class="rounded border transition-colors px-1 text-sm relative before:transition-opacity before:absolute before:inset-0 before:bg-current before:opacity-0 hover:before:opacity-20"
		:class="[
			{
				[props.activeClass]: active,
			},
			props.borderClass,
		]"
		:disabled="isUpdating"
		@click="toggleFilter"
	>
		{{ props.filter.label }}
	</button>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import { useRestApi } from "composables";
import { vRipple } from "directives";

const props = defineProps({
	filter: {
		type: Object,
		default: () => ({}),
	},
	activeClass: {
		type: String,
		default: "bg-secondary-900 !border-secondary-700",
	},
	borderClass: {
		type: String,
		default: "border-white/40",
	},
	propsToReload: {
		type: [String, Array],
		default: () => [],
	},
});

let active = ref(props.filter.active);
let isUpdating = ref(false);
const restApi = useRestApi();

const toggleFilter = () => {
	if (isUpdating.value) return false;

	active.value = !active.value;
	isUpdating.value = true;

	const normalisedProps = Array.isArray(props.propsToReload) ? props.propsToReload : [props.propsToReload];
	normalisedProps.push("filters");

	restApi
		.patch("filters", {
			slug: props.filter.slug,
			active: active.value,
		})
		.then(() => {
			router.reload({
				only: normalisedProps,
				onFinish() {
					isUpdating.value = false;
				},
			});
		});
};
</script>
