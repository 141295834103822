<template>
	<div
		class="overflow-hidden relative"
		:style="{
			'--scroll-duration': props.duration,
			'--animation-offset': props.animationOffset,
		}"
	>
		<div class="marquee flex absolute top-1/2 left-0 whitespace-nowrap" :class="className">
			<slot />
		</div>
		<div class="marquee-clone flex absolute top-1/2 left-0 whitespace-nowrap translate-x-full" :class="className">
			<slot />
		</div>
		<slot name="footer"></slot>
	</div>
</template>

<script setup>
import { useVModel } from "@vueuse/core";
const emit = defineEmits(["update:paused"]);
const props = defineProps({
	animationOffset: {
		type: String,
		default: "0s",
	},
	duration: {
		type: String,
		default: "30s",
	},
	wrapperClass: {
		type: String,
		default: "",
	},
	paused: {
		type: Boolean,
		default: false,
	},
});

const isPaused = useVModel(props, "paused", emit);
const className = computed(() => [
	props.wrapperClass,
	{
		"is-paused": isPaused.value,
	},
]);
</script>

<style>
.marquee,
.marquee-clone {
	animation: marquee var(--scroll-duration) linear infinite;
}

.is-paused {
	animation-play-state: paused;
}

.marquee {
	animation-delay: calc(((var(--scroll-duration) / 2) * -1) - var(--animation-offset));
}
.marquee-clone {
	animation-delay: calc(var(--animation-offset) * -1);
}

@keyframes marquee {
	0% {
		transform: translate3d(100%, -50%, 0);
	}
	100% {
		transform: translate3d(-100%, -50%, 0);
	}
}
</style>
