import { defineStore } from "pinia";
import { isRef } from "vue";

let intersectionOptions = {
	rootMargin: "-64px 0px 0px 0px",
	threshold: 1.0,
};

const asyncDom = (selector) =>
	new Promise((resolve, reject) => {
		let attempts = 0;
		let interval = setInterval(() => {
			const el = document.querySelector(selector);
			if (el) resolve(el);
			else attempts++;

			if (attempts > 10) {
				clearInterval(interval);
				reject("Unable to find " + selector);
			}
		}, 50);
	});

export const useAppStateStore = defineStore("appStateStore", {
	state: () => {
		return {
			isCmsLoaded: false,
			mobileMobileVisible: false,
			contentsTabVisible: true,
			headings: [],
			headingCallback: null,
			activeHeader: null,
		};
	},
	actions: {
		resetHeadings() {
			this.headings = [];
			this.headingCallback = null;
			this.activeHeader = null;
		},
		async createHeadingsList(content) {
			this.resetHeadings();

			content = isRef(content) ? content.value : content;
			const wrapper = document.createElement("main");
			wrapper.innerHTML = content.replace(/ScwEffectHeadingAppear/gi, "h2");
			const headingEls = wrapper.querySelectorAll("h2");

			for (let i = 0; i < headingEls.length; i++) {
				const link = `#${headingEls[i].id}`;
				const title = headingEls[i].textContent;
				const index = i + 1;

				let observer = new IntersectionObserver(([{ isIntersecting }]) => {
					if (!this.headings[i]) return false;
					this.headings[i].active = isIntersecting;
					if (isIntersecting) {
						this.activeHeader = i + 1;
					}

					if (this.headingCallback && typeof this.headingCallback === "function") {
						this.headingCallback();
					}
				}, intersectionOptions);
				try {
					const el = await asyncDom(`#${headingEls[i].id}`);

					observer.observe(el);

					this.headings.push({
						link,
						title,
						index,
						active: false,
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
	},
});
