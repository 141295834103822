<template>
	<div
		class="relative"
		:class="{
			'pb-4': !props.hideDetails,
			'animate-shake': isShaking,
		}"
		@animationend="isShaking = false"
	>
		<div class="relative">
			<div
				class="rounded-t-lg text-gray-900 bg-gray-200 border-0 border-b-2 border-gray-300 focus-within:ring-0 focus-within:border-primary-900 flex items-center"
			>
				<div v-if="$slots['prepend-inner']" class="px-4">
					<slot name="prepend-inner"></slot>
				</div>
				<slot>
					<input
						:id="props.id"
						v-model="proxyValue"
						:type="props.type"
						class="block px-2.5 pb-2.5 pt-5 w-full text-sm appearance-none bg-transparent focus:outline-none peer"
						placeholder=" "
						:disabled="props.readonly"
					/>
					<label
						:for="props.id"
						class="absolute text-sm text-gray-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
						:class="{
							'!left-12': $slots['prepend-inner'],
						}"
					>
						{{ props.label }}
						<span v-if="props.required" class="text-red-600">*</span>
					</label>
				</slot>

				<div v-if="$slots['append-inner']" class="px-4">
					<slot name="append-inner"></slot>
				</div>
			</div>
		</div>
		<ScwInputMessages v-model:is-shaking="isShaking" :error="props.error" />
	</div>
</template>

<script setup>
import { useVModel } from "@vueuse/core";
import { randomString } from "helpers";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
	dense: {
		type: Boolean,
		default: false,
	},
	error: {
		type: [String, Array],
		default: "",
	},
	id: {
		type: String,
		default: () => randomString(),
	},
	label: {
		type: String,
		default: "",
	},
	modelValue: {
		type: String,
		default: "",
	},
	type: {
		type: String,
		default: "text",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
});

const proxyValue = useVModel(props, "modelValue", emit);
const isShaking = ref(false);
</script>
