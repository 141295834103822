import { createPinia } from "pinia";
import { piniaUniversalPersist } from "pinia-plugin-universal-persist";
export * from "./survey";
export * from "./settings";
export * from "./appState";

const pinia = createPinia({});
pinia.use(piniaUniversalPersist);

export { pinia };
