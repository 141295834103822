<template>
	<svg
		data-name="Logo Dark"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 265 67"
	>
		<defs>
			<linearGradient :id="gradientIdOne" x1="10" y1="25.5" x2="73" y2="25.5" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#18a8e4"></stop>
				<stop offset="1" stop-color="#9d00d3"></stop>
			</linearGradient>
			<linearGradient :id="gradientIdTwo" x1="41.5" y1="42.5" x2="41.5" y2="8.5" gradientUnits="userSpaceOnUse">
				<stop offset="0.35" stop-color="#1d1d1f" stop-opacity="0.2" />
				<stop offset="0.54" stop-color="#1d1d1f" stop-opacity="0" />
			</linearGradient>
			<linearGradient
				:id="gradientIdThree"
				x1="71.88"
				y1="42.59"
				x2="8.88"
				y2="42.59"
				:xlink:href="`#${gradientIdOne}`"
			/>
		</defs>
		<path
			:style="{
				fill: gradientUrlOne,
			}"
			d="M40,25.41,10,42.5V25.59L40,8.5Zm3,0L73,42.5V25.59L43,8.5Z"
		></path>
		<path
			:style="{
				fill: gradientUrlTwo,
			}"
			d="M40,25.41,10,42.5V25.59L40,8.5Zm3,0L73,42.5V25.59L43,8.5Z"
		></path>
		<path
			:style="{
				fill: gradientUrlThree,
			}"
			d="M73,42.5,43,59.59V42.68L73,25.59Zm-63,0L40,59.59V42.68L25,34.12,10,25.59Z"
		></path>
		<path
			class="fill-current"
			d="M88.23,38.15a3.48,3.48,0,0,0,1.21,1,3.86,3.86,0,0,0,1.75.37,2.82,2.82,0,0,0,1.89-.6,2.07,2.07,0,0,0,.72-1.67,1.9,1.9,0,0,0-.45-1.3A3.46,3.46,0,0,0,92.17,35a13.5,13.5,0,0,0-1.57-.63,17.78,17.78,0,0,1-1.73-.76,3.94,3.94,0,0,1-1.39-1.13,3,3,0,0,1-.54-1.86,3,3,0,0,1,.54-1.82,3.21,3.21,0,0,1,1.43-1.08,5.16,5.16,0,0,1,1.89-.35,5.07,5.07,0,0,1,2,.36,5,5,0,0,1,1.49,1,4.43,4.43,0,0,1,1,1.31L94,30.76a4.48,4.48,0,0,0-1.26-1.46,3.38,3.38,0,0,0-2.1-.61,2.73,2.73,0,0,0-1.61.47,1.62,1.62,0,0,0-.65,1.4,1.85,1.85,0,0,0,.42,1.25,3,3,0,0,0,1.12.8c.46.2,1,.4,1.48.59a17.06,17.06,0,0,1,1.92.86,4.64,4.64,0,0,1,1.43,1.18A2.85,2.85,0,0,1,95.28,37a3.52,3.52,0,0,1-1.16,2.73,4.33,4.33,0,0,1-3,1.05,5.46,5.46,0,0,1-2.34-.47,4.43,4.43,0,0,1-1.59-1.24,5.13,5.13,0,0,1-.89-1.56l1.18-.68A4.92,4.92,0,0,0,88.23,38.15Z"
		></path>
		<path
			class="fill-current"
			d="M98.53,30.62a6.37,6.37,0,0,1,2.37-2.38,7,7,0,0,1,6.74,0A6.37,6.37,0,0,1,110,30.62a7.34,7.34,0,0,1,0,6.93,6.32,6.32,0,0,1-2.37,2.39,7,7,0,0,1-6.74,0,6.32,6.32,0,0,1-2.37-2.39,7.34,7.34,0,0,1,0-6.93Zm1.29,6.24a4.89,4.89,0,0,0,1.83,1.89,5.32,5.32,0,0,0,5.24,0,4.89,4.89,0,0,0,1.83-1.89,5.54,5.54,0,0,0,.67-2.77,5.61,5.61,0,0,0-.67-2.76,5,5,0,0,0-1.83-1.9,5.05,5.05,0,0,0-2.62-.69,5.14,5.14,0,0,0-2.62.68,4.76,4.76,0,0,0-1.83,1.89,5.63,5.63,0,0,0-.67,2.78A5.54,5.54,0,0,0,99.82,36.86Z"
		></path>
		<path
			class="fill-current"
			d="M116.37,38.47a2.66,2.66,0,0,0,2.29,1,3.65,3.65,0,0,0,2-.53A3.84,3.84,0,0,0,122,37.48a4,4,0,0,0,.49-2V27.65h1.46V40.53h-1.46V37.84a4.7,4.7,0,0,1-1.6,2.19,4.14,4.14,0,0,1-2.51.78,3.72,3.72,0,0,1-3.08-1.3A5.39,5.39,0,0,1,114.18,36V27.65h1.46v8.18A4.25,4.25,0,0,0,116.37,38.47Z"
		></path>
		<path class="fill-current" d="M132,27.65V29h-5.66V27.65Zm-2.1-4.48V40.53h-1.46V23.17Z"></path>
		<path
			class="fill-current"
			d="M135.94,40.53h-1.46V18.69h1.46ZM142,29.67a2.74,2.74,0,0,0-2.31-.93,3.57,3.57,0,0,0-1.95.54,3.73,3.73,0,0,0-1.34,1.42,4,4,0,0,0-.49,2h-.51A6,6,0,0,1,136,30a5,5,0,0,1,1.64-1.89,4.14,4.14,0,0,1,2.38-.7,4.34,4.34,0,0,1,2.27.55,3.41,3.41,0,0,1,1.4,1.59,6.17,6.17,0,0,1,.48,2.57v8.45h-1.46V32.24A4.13,4.13,0,0,0,142,29.67Z"
		></path>
		<path
			class="fill-current"
			d="M149.52,36.86a4.82,4.82,0,0,0,1.83,1.89,5.05,5.05,0,0,0,2.62.69,5.79,5.79,0,0,0,1.93-.31,5.27,5.27,0,0,0,1.55-.8,3.08,3.08,0,0,0,.94-1v1.82a4.37,4.37,0,0,1-1.8,1.18,7.34,7.34,0,0,1-2.62.47,6.45,6.45,0,0,1-5.73-3.26,7.26,7.26,0,0,1,0-6.93A6.47,6.47,0,0,1,154,27.37a6.94,6.94,0,0,1,2.62.49,4.79,4.79,0,0,1,1.8,1.16v1.82a2.92,2.92,0,0,0-.94-1,5.78,5.78,0,0,0-1.55-.79,6.05,6.05,0,0,0-1.93-.31,5.05,5.05,0,0,0-2.62.69,5,5,0,0,0-1.83,1.89,6,6,0,0,0,0,5.54Z"
		></path>
		<path
			class="fill-current"
			d="M161.86,30.62a6.37,6.37,0,0,1,2.37-2.38,7,7,0,0,1,6.75,0,6.34,6.34,0,0,1,2.36,2.38,7.34,7.34,0,0,1,0,6.93A6.3,6.3,0,0,1,171,39.94a7,7,0,0,1-6.75,0,6.32,6.32,0,0,1-2.37-2.39,7.41,7.41,0,0,1,0-6.93Zm1.29,6.24A4.85,4.85,0,0,0,165,38.75a5.3,5.3,0,0,0,5.23,0,4.85,4.85,0,0,0,1.84-1.89,5.65,5.65,0,0,0,.67-2.77,5.72,5.72,0,0,0-.67-2.76,4.94,4.94,0,0,0-1.84-1.9,5,5,0,0,0-2.62-.69,5.08,5.08,0,0,0-2.61.68,4.72,4.72,0,0,0-1.84,1.89,5.63,5.63,0,0,0-.67,2.78A5.54,5.54,0,0,0,163.15,36.86Z"
		></path>
		<path
			class="fill-current"
			d="M178.17,38.26a2.69,2.69,0,0,0,1.18,1,4.3,4.3,0,0,0,3.64-.18,3.76,3.76,0,0,0,1.47-1.49,4.79,4.79,0,0,0,.56-2.4l.34,1.23a4.09,4.09,0,0,1-.73,2.49,4.53,4.53,0,0,1-1.82,1.46,5.63,5.63,0,0,1-2.21.47,4.94,4.94,0,0,1-2.1-.46A3.87,3.87,0,0,1,176.87,39a3.92,3.92,0,0,1-.61-2.27,3.64,3.64,0,0,1,1.3-3,5.34,5.34,0,0,1,3.54-1.1,7,7,0,0,1,2.17.3,6.22,6.22,0,0,1,1.5.66,4.84,4.84,0,0,1,.84.61v.93a7.11,7.11,0,0,0-2-1,8,8,0,0,0-2.26-.32,5.2,5.2,0,0,0-1.89.31,2.48,2.48,0,0,0-1.69,2.52A2.78,2.78,0,0,0,178.17,38.26Zm-1.1-9.35a11.43,11.43,0,0,1,1.89-1,7,7,0,0,1,2.87-.52,5.88,5.88,0,0,1,2.49.48,3.65,3.65,0,0,1,1.6,1.37,4.06,4.06,0,0,1,.56,2.18v9.13H185V31.6a3.59,3.59,0,0,0-.17-1.08,2.62,2.62,0,0,0-.54-.94,2.75,2.75,0,0,0-1-.67,4.25,4.25,0,0,0-1.56-.25,6,6,0,0,0-1.71.22,5.85,5.85,0,0,0-1.3.54,7.28,7.28,0,0,0-.91.58Z"
		></path>
		<path
			class="fill-current"
			d="M191.21,38.15a3.43,3.43,0,0,0,1.22,1,3.8,3.8,0,0,0,1.75.37,2.86,2.86,0,0,0,1.89-.6,2.1,2.1,0,0,0,.71-1.67,1.9,1.9,0,0,0-.45-1.3,3.51,3.51,0,0,0-1.17-.87,15.1,15.1,0,0,0-1.57-.63,16.94,16.94,0,0,1-1.74-.76,3.82,3.82,0,0,1-1.38-1.13,3,3,0,0,1-.55-1.86,2.9,2.9,0,0,1,.55-1.82,3.18,3.18,0,0,1,1.42-1.08,5.16,5.16,0,0,1,1.89-.35,5,5,0,0,1,2,.36,4.84,4.84,0,0,1,1.49,1,4.26,4.26,0,0,1,1,1.31l-1.2.73a4.48,4.48,0,0,0-1.26-1.46,3.42,3.42,0,0,0-2.1-.61,2.69,2.69,0,0,0-1.61.47,1.63,1.63,0,0,0-.66,1.4,1.85,1.85,0,0,0,.42,1.25,3.07,3.07,0,0,0,1.12.8c.46.2,1,.4,1.48.59a17.06,17.06,0,0,1,1.92.86,4.52,4.52,0,0,1,1.43,1.18,2.85,2.85,0,0,1,.54,1.79,3.49,3.49,0,0,1-1.16,2.73,4.31,4.31,0,0,1-3,1.05,5.52,5.52,0,0,1-2.34-.47,4.43,4.43,0,0,1-1.59-1.24,5.1,5.1,0,0,1-.88-1.56l1.17-.68A4.92,4.92,0,0,0,191.21,38.15Z"
		></path>
		<path class="fill-current" d="M205.46,27.65V29H199.8V27.65Zm-2.1-4.48V40.53H201.9V23.17Z"></path>
		<path
			class="fill-current"
			d="M208.37,27.65,211.59,36l3.5-9.35,3.75,9.41,3.25-8.43h2.52L219,41.51l-3.89-9.38-3.67,9.38-5.6-13.86Z"
		></path>
		<path
			class="fill-current"
			d="M228.24,40a5.76,5.76,0,0,1-2.16-2.37,7.65,7.65,0,0,1-.77-3.5,7.5,7.5,0,0,1,.8-3.53,5.83,5.83,0,0,1,2.24-2.35,6.54,6.54,0,0,1,3.35-.84,6.38,6.38,0,0,1,3.26.8A5.23,5.23,0,0,1,237,30.45a7.89,7.89,0,0,1,.73,3.53c0,.13,0,.27,0,.41s0,.22,0,.26h-11v-1.9H236l-.9,1.29a1.61,1.61,0,0,0,.17-.44,1.65,1.65,0,0,0,.08-.49,4.06,4.06,0,0,0-.46-2,3.33,3.33,0,0,0-1.27-1.31,3.67,3.67,0,0,0-1.9-.48,4.13,4.13,0,0,0-2.2.55,3.46,3.46,0,0,0-1.4,1.57,6,6,0,0,0-.52,2.5,6.43,6.43,0,0,0,.47,2.58,3.5,3.5,0,0,0,1.41,1.62,4.25,4.25,0,0,0,2.24.56,4.64,4.64,0,0,0,2.32-.57,4.87,4.87,0,0,0,1.71-1.75l1.82,1.15A6.76,6.76,0,0,1,235,40a7.41,7.41,0,0,1-3.59.82A5.91,5.91,0,0,1,228.24,40Z"
		></path>
		<path
			class="fill-current"
			d="M243,40.53h-2.24V18.69H243Zm9.91-2.85A6.13,6.13,0,0,1,250.55,40a6.42,6.42,0,0,1-3.2.81,5,5,0,0,1-2.82-.81,5.43,5.43,0,0,1-1.9-2.32,8.61,8.61,0,0,1-.69-3.59,8.56,8.56,0,0,1,.69-3.6,5.52,5.52,0,0,1,1.9-2.31,5,5,0,0,1,2.82-.81,6.42,6.42,0,0,1,3.2.81,6.24,6.24,0,0,1,2.31,2.31,7.06,7.06,0,0,1,.87,3.6A7.1,7.1,0,0,1,252.86,37.68Zm-2-6.11a4,4,0,0,0-1.6-1.57,4.6,4.6,0,0,0-2.19-.53,4,4,0,0,0-1.94.53,4.27,4.27,0,0,0-1.56,1.57,5.43,5.43,0,0,0,0,5,4.27,4.27,0,0,0,1.56,1.57,4,4,0,0,0,1.94.53,4.6,4.6,0,0,0,2.19-.53,4,4,0,0,0,1.6-1.57,5.59,5.59,0,0,0,0-5Z"
		></path>
	</svg>
</template>

<script setup>
import { randomString } from "helpers";

const gradientIdOne = ref(randomString(8, false));
const gradientUrlOne = computed(() => `url(#${gradientIdOne.value})`);
const gradientIdTwo = ref(randomString(8, false));
const gradientUrlTwo = computed(() => `url(#${gradientIdTwo.value})`);
const gradientIdThree = ref(randomString(8, false));
const gradientUrlThree = computed(() => `url(#${gradientIdThree.value})`);
</script>
