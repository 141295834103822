/* ******************************************
 * Constants
 ****************************************** */
const DELAY_RIPPLE = 80;

/* ******************************************
 * Tailwind Classes
 ****************************************** */
const containerTailwindClasses =
  "absolute w-full h-full left-0 top-0 overflow-hidden z-0 pointer-events-none";
const animationTailwindClasses =
  "text-inherit absolute top-0 left-0 rounded-full bg-current opacity-0 pointer-events-none overflow-hidden";

const animationEnterTailwindClasses = "transition-none opacity-0";
const animationInTailwindClasses = "transition-linear";
const animationOutTailwindClasses = "opacity-0";

/**
 * transform
 *
 * @param { HTMLElement } el - The ripple animation element to transform
 * @param { string } value - The CSS transform value
 */
function transform(el, value) {
  el.style.transform = value;
}

/**
 * isTouchEvent
 *
 * @description - Assesses whether the received user interaction event was a "Touch Event"
 * @param { Event } e - The user interaction event
 * @returns { boolean } - Whether event was a touch event
 */
function isTouchEvent(e) {
  return e.constructor.name === "TouchEvent";
}

/**
 * isKeyboardEvent
 *
 * @description - Assesses whether the received user interaction event was a "Keyboard Event"
 * @param { Event } e - The user interaction event
 * @returns { boolean } - Whether event was a keyboard event
 */
function isKeyboardEvent(e) {
  return e.constructor.name === "KeyboardEvent";
}

const calculate = (e, el, value = {}) => {
  let localX = 0;
  let localY = 0;

  if (!isKeyboardEvent(e)) {
    const offset = el.getBoundingClientRect();
    const target = isTouchEvent(e) ? e.touches[e.touches.length - 1] : e;

    localX = target.clientX - offset.left;
    localY = target.clientY - offset.top;
  }

  let radius = 0;
  let scale = 0.3;
  if (el._ripple && el._ripple.circle) {
    scale = 0.15;
    radius = el.clientWidth / 2;
    radius = value.centre
      ? radius
      : radius + Math.sqrt((localX - radius) ** 2 + (localY - radius) ** 2) / 4;
  } else {
    radius = Math.sqrt(el.clientWidth ** 2 + el.clientHeight ** 2) / 2;
  }

  const centreX = `${(el.clientWidth - radius * 2) / 2}px`;
  const centreY = `${(el.clientHeight - radius * 2) / 2}px`;

  const x = value.centre ? centreX : `${localX - radius}px`;
  const y = value.centre ? centreY : `${localY - radius}px`;

  return { radius, scale, x, y, centreX, centreY };
};

const ripples = {
  show(e, el, value = {}) {
    if (!el._ripple || !el._ripple.enabled) {
      return;
    }

    const container = document.createElement("span");
    const animation = document.createElement("span");

    container.appendChild(animation);
    container.className = "aui-v-ripple__container " + containerTailwindClasses;

    if (value.class) {
      container.className += ` ${value.class}`;
    } else container.className += " text-inherit";

    const { radius, scale, x, y, centreX, centreY } = calculate(e, el, value);

    const size = `${radius * 2}px`;
    animation.className = "aui-v-ripple__animation " + animationTailwindClasses;
    animation.style.width = size;
    animation.style.height = size;

    el.appendChild(container);

    const computed = window.getComputedStyle(el);
    if (computed && computed.position === "static") {
      el.style.position = "relative";
      el.dataset.previousPosition = "static";
    }

    animation.classList.add(
      "aui-v-ripple__animation--enter",
      ...animationEnterTailwindClasses.split(" ")
    );
    animation.classList.add("aui-v-ripple__animation--visible");
    transform(
      animation,
      `translate(${x}, ${y}) scale3d(${scale},${scale},${scale})`
    );
    animation.dataset.activated = String(performance.now());

    setTimeout(() => {
      animation.classList.remove(
        "aui-v-ripple__animation--enter",
        ...animationEnterTailwindClasses.split(" ")
      );
      animation.classList.add(
        "aui-v-ripple__animation--in",
        ...animationInTailwindClasses.split(" ")
      );
      transform(animation, `translate(${centreX}, ${centreY}) scale3d(1,1,1)`);
    }, 0);
  },

  hide(el) {
    if (!el || !el._ripple || !el._ripple.enabled) return;

    const ripples = el.getElementsByClassName("aui-v-ripple__animation");

    if (ripples.length === 0) return;
    const animation = ripples[ripples.length - 1];

    if (animation.dataset.isHiding) return;
    else animation.dataset.isHiding = "true";

    const diff = performance.now() - Number(animation.dataset.activated);
    const delay = Math.max(250 - diff, 0);

    setTimeout(() => {
      animation.classList.remove(
        "aui-v-ripple__animation--in",
        ...animationInTailwindClasses.split(" ")
      );
      animation.classList.add(
        "aui-v-ripple__animation--out",
        ...animationOutTailwindClasses.split(" ")
      );

      setTimeout(() => {
        const ripples = el.getElementsByClassName("aui-v-ripple__animation");
        if (ripples.length === 1 && el.dataset.previousPosition) {
          el.style.position = el.dataset.previousPosition;
          delete el.dataset.previousPosition;
        }

        animation.parentNode && el.removeChild(animation.parentNode);
      }, 300);
    }, delay);
  },
};

/**
 * isRippleEnabled
 *
 * @description - Analyses whether ripple is currently enabled
 * @param { object | undefined } value - The element's binding value
 * @returns { boolean } - Was ripple enabled?
 */
function isRippleEnabled(value) {
  return typeof value === "undefined" || !!value;
}

/**
 * rippleShow
 *
 * @description - Handle showing ripples for a touch event, or call a click ripple otherwise
 * @param { Event } e - User interaction event
 */
function rippleShow(e) {
  const value = {};
  const element = e.currentTarget;

  if (!element || !element._ripple || element._ripple.touched || e.rippleStop)
    return;

  // Don't allow the event to trigger ripples on any other elements
  e.rippleStop = true;

  if (isTouchEvent(e)) {
    element._ripple.touched = true;
    element._ripple.isTouch = true;
  } else {
    if (element._ripple.isTouch) return;
  }
  value.centre = element._ripple.centred || isKeyboardEvent(e);
  if (element._ripple.class) {
    value.class = element._ripple.class;
  }

  if (isTouchEvent(e)) {
    if (element._ripple.showTimerCommit) return;

    element._ripple.showTimerCommit = () => {
      ripples.show(e, element, value);
    };
    element._ripple.showTimer = window.setTimeout(() => {
      if (element && element._ripple && element._ripple.showTimerCommit) {
        element._ripple.showTimerCommit();
        element._ripple.showTimerCommit = null;
      }
    }, DELAY_RIPPLE);
  } else {
    ripples.show(e, element, value);
  }
}

/**
 * rippleHide
 *
 * @description - Hides ending ripple and clears all connected timeouts
 * @param { Event } e - User interaction event
 */
function rippleHide(e) {
  const element = e.currentTarget;
  if (!element || !element._ripple) return;

  window.clearTimeout(element._ripple.showTimer);

  if (e.type === "touchend" && element._ripple.showTimerCommit) {
    element._ripple.showTimerCommit();
    element._ripple.showTimerCommit = null;

    element._ripple.showTimer = setTimeout(() => {
      rippleHide(e);
    });
    return;
  }

  window.setTimeout(() => {
    if (element._ripple) {
      element._ripple.touched = false;
    }
  });
  ripples.hide(element);
}

/**
 * rippleCancelShow
 *
 * @description - Cancel active ripple
 * @param { Event } e - User interaction event
 */
function rippleCancelShow(e) {
  const element = e.currentTarget;

  if (!element || !element._ripple) return;

  if (element._ripple.showTimerCommit) {
    element._ripple.showTimerCommit = null;
  }

  window.clearTimeout(element._ripple.showTimer);
}

let keyboardRipple = false;

/**
 * keyboardRippleShow
 *
 * @description - On a keyboard event, check for a spacebar or enter press and dispatch ripple if detected
 * @param { Event } e - User interaction event
 */
function keyboardRippleShow(e) {
  const keyWasValid = /(^\s$|^enter$)/i.test(e.key);
  if (!keyboardRipple && keyWasValid) {
    keyboardRipple = true;
    rippleShow(e);
  }
}

/**
 * keyboardRippleHide
 *
 * @description - Hide active ripples when the key is released
 * @param { Event } e - User interaction event
 */
function keyboardRippleHide(e) {
  keyboardRipple = false;
  rippleHide(e);
}

/**
 * focusRippleHide
 *
 * @description - Hide active ripples when the element loses focus
 * @param { Event } e - User interaction event
 */
function focusRippleHide(e) {
  if (keyboardRipple === true) {
    keyboardRipple = false;
    rippleHide(e);
  }
}

/**
 * updateRipple
 *
 * @description - Hide active ripples when the key is released
 * @param { HTMLElement } el - The element the ripple is bound to
 * @param { object | undefined } binding - The object bound with the directive
 * @param { boolean } wasEnabled - Was ripple previous enabled prior to the element updating?
 */
function updateRipple(el, binding, wasEnabled) {
  const enabled = isRippleEnabled(binding.value);
  if (!enabled) {
    ripples.hide(el);
  }
  el._ripple = el._ripple || {};
  el._ripple.enabled = enabled;
  const value = binding.value || {};
  if (value.centre) {
    el._ripple.centred = true;
  }
  if (value.class) {
    el._ripple.class = binding.value.class;
  }
  if (value.circle) {
    el._ripple.circle = value.circle;
  }
  if (enabled && !wasEnabled) {
    el.addEventListener("touchstart", rippleShow, { passive: true });
    el.addEventListener("touchend", rippleHide, { passive: true });
    el.addEventListener("touchmove", rippleCancelShow, { passive: true });
    el.addEventListener("touchcancel", rippleHide);
    el.addEventListener("mousedown", rippleShow);
    el.addEventListener("mouseup", rippleHide);
    el.addEventListener("mouseleave", rippleHide);
    el.addEventListener("keydown", keyboardRippleShow);
    el.addEventListener("keyup", keyboardRippleHide);
    el.addEventListener("blur", focusRippleHide);

    // Anchor tags can be dragged, causes other hides to fail - #1537
    el.addEventListener("dragstart", rippleHide, { passive: true });
  } else if (!enabled && wasEnabled) {
    removeListeners(el);
  }
}

/**
 * removeListeners
 *
 * @description - Remove all event listeners from the elemenet if the directive is removed
 * @param { HTMLElement } el - The HTML element to which the directive is bound
 */
function removeListeners(el) {
  el.removeEventListener("mousedown", rippleShow);
  el.removeEventListener("touchstart", rippleShow);
  el.removeEventListener("touchend", rippleHide);
  el.removeEventListener("touchmove", rippleCancelShow);
  el.removeEventListener("touchcancel", rippleHide);
  el.removeEventListener("mouseup", rippleHide);
  el.removeEventListener("mouseleave", rippleHide);
  el.removeEventListener("keydown", keyboardRippleShow);
  el.removeEventListener("keyup", keyboardRippleHide);
  el.removeEventListener("dragstart", rippleHide);
  el.removeEventListener("blur", focusRippleHide);
}

/**
 * checkRippleClass
 *
 * @description - Checks whether the correct text-based ripple class has been used
 * @param { object | undefined } value - The directive's binding value
 */
function checkRippleClass(value) {
  if (value?.class && /^text/.test(value.class.trim()) === false) {
    console.warn(
      `AuiVRipple: You must use a Tailwind "text-" class to control ripple colour, used "${value.class}"`
    );
  }
}

/**
 * directive
 *
 * @description - Setup for when the directive is first created on the element
 * @param { HTMLElement } el - The HTML element to which the directive is bound
 * @param { object } binding - The object bound with the directive
 */
function directive(el, binding) {
  if (typeof window === "undefined") return;
  checkRippleClass(binding.value);
  updateRipple(el, binding, false);
}

/**
 * unmounted
 *
 * @description - Teardown for when the directive is removed
 * @param { HTMLElement } el - The HTML element to which the directive is bound
 */
function unmounted(el) {
  if (typeof window === "undefined") return;

  delete el._ripple;
  removeListeners(el);
}

/**
 * updated
 *
 * @description - If the directive options change, re-run setup
 * @param { HTMLElement } el - The HTML element to which the directive is bound
 * @param { object } binding - The object bound with the directive
 */
function updated(el, binding) {
  if (binding.value === binding.oldValue || typeof window === "undefined") {
    return;
  }

  const wasEnabled = isRippleEnabled(binding.oldValue);
  updateRipple(el, binding, wasEnabled);
}

export default {
  created: directive,
  unmounted,
  updated,
};
