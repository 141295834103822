<template>
	<Head>
		<link head-key="favicon-light" type="image/svg+xml" :href="Logo" rel="icon" />
		<title v-if="smartCrawl && smartCrawl.title">{{ smartCrawl.title }}</title>
		<meta v-for="tag in metaTags" :key="tag.key" v-bind="tag" />
		<link v-for="tag in linkTags" :key="tag.name" :head-key="tag.name" v-bind="tag" />
	</Head>
</template>

<script setup>
import { Head, usePage } from "@inertiajs/vue3";
import Logo from "icons/scw-logo.svg";

const smartCrawl = computed(() => usePage().props.modules?.smartCrawl || {});
const metaTags = computed(() =>
	smartCrawl.value.meta.map((tag) => ({
		...tag,
		key: tag.name ?? tag.property,
	}))
);
const linkTags = computed(() => smartCrawl.value.link);
</script>
