<template>
	<div class="relative">
		<div
			ref="frameRef"
			class="scw-perspective-frame relative"
			:class="{
				'cursor-none': !props.disabled,
			}"
			:style="{
				width: normalisedWidth,
				zIndex: normalisedZIndex,
			}"
		>
			<ScwDisplayResponsiveImage class="w-full h-full object-cover" :media="props.media" eager />
		</div>
		<teleport v-if="isMounted" to="#vue-modals">
			<Transition :css="false" @enter="showPopover" @leave="hidePopover">
				<div
					v-if="isHovered && !props.disabled"
					ref="wrapperRef"
					class="fixed pointer-events-none z-[9999] -translate-y-2/3 -translate-x-1/2 origin-center opacity-0 scale-0"
					:style="{
						height: windowHeight / 1.5 + 'px',
						width: (windowHeight / 1.5) * 0.75 + 'px',
						perspective: '1000px',
					}"
				>
					<div
						class="bg-white border border-gray-200 rounded-sm shadow-xl h-full transform-gpu"
						:style="{
							transform: `rotateX(${panY}) rotateY(${panX})`,
						}"
					>
						<ScwDisplayResponsiveImage class="w-full max-h-full" :media="props.media" eager />
					</div>
				</div>
			</Transition>
		</teleport>
	</div>
</template>

<script setup>
import { useMouse, useMouseInElement, useElementHover, useWindowSize } from "@vueuse/core";
import { gsap } from "gsap";

const TILT_MAX = 30;

const emit = defineEmits(["hover-on", "hover-off"]);
const props = defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	media: {
		type: Object,
		default: () => ({}),
	},
	width: {
		type: String,
		default: "800",
	},
	zIndex: {
		type: Number,
		default: null,
	},
});

const normalisedZIndex = computed(() => (!props.zIndex || isNaN(props.zIndex) ? 0 : props.zIndex));

const frameRef = ref(null);
const isHovered = useElementHover(frameRef);
const isMounted = ref(false);
onMounted(() => (isMounted.value = true));
watch(isHovered, (v) => {
	if (v) emit("hover-on");
	else emit("hover-off");
});
const { elementX, elementY, elementWidth, elementHeight } = useMouseInElement(frameRef);

const calculateTilt = (position, dimension) => {
	const raw = (100 / dimension) * position;
	const percent = raw < 0 ? 0 : raw > 100 ? 100 : raw;
	const degreeTilt = (percent / 100) * TILT_MAX;
	const tiltOffset = TILT_MAX / 2;
	return degreeTilt - tiltOffset;
};

const panX = computed(() => {
	return `${calculateTilt(elementX.value, elementWidth.value) * -1}deg`;
});

const panY = computed(() => {
	return `${calculateTilt(elementY.value, elementHeight.value)}deg`;
});

const normalisedWidth = computed(() => {
	if (/[^0-9]/.test(props.width) === false) return `${props.width}px`;
	else return props.width;
});

const { x, y } = useMouse({ type: "client" });
const { height: windowHeight } = useWindowSize();

const wrapperRef = ref(null);
watch([x, y], ([toX, toY], [fromX, fromY]) => {
	if (!wrapperRef.value) return;
	gsap.fromTo(
		wrapperRef.value,
		{
			left: fromX,
			top: fromY,
		},
		{
			left: toX,
			top: toY,
			ease: "sine.out",
		}
	);
});

const showPopover = (el, done) => {
	if (props.disabled || !el) return done();

	gsap.to(el, {
		opacity: 1,
		scale: 1,
		duration: 0.3,
		onComplete: done,
	});
};
const hidePopover = (el, done) => {
	if (props.disabled || !el) return done();

	gsap.to(el, {
		opacity: 0,
		scale: 0,
		duration: 0.3,
		onComplete: done,
	});
};
</script>

<style lang="postcss">
.scw-perspective-frame {
	transform: perspective(10000px) rotateY(15deg) rotateX(45deg) rotateZ(-35deg) scale(1) translate(100px, 30px);
	box-shadow: -5px 3px 3px 1px #ccc, -1px 1px 0px #aaa, -2px 2px 0px #aaa, -3px 3px 0px #aaa, -3px 3px 0px #aaa,
		-1px 1px 0px #aaa, -20px 20px 12px rgba(0, 30, 50, 0.15);
	border-radius: 5px;
	border: solid 5px #fafafa;
	--zoom-offset: 0px;
}
</style>
