<template>
	<Splitting
		:id="props.id"
		ref="headingRef"
		class="appear-heading"
		:class="{
			'is-visible': isVisible,
		}"
	/>
</template>

<script setup>
import { useSplitting } from "vue3-splitting";
import { useElementVisibility } from "@vueuse/core";

let headingRef = ref(null);

const props = defineProps({
	id: {
		type: String,
		default: undefined,
	},
	text: {
		type: String,
		default: "",
	},
	tag: {
		type: String,
		default: "h2",
	},
});

const isVisible = useElementVisibility(headingRef);

const { Splitting } = useSplitting(props.text, {
	wrapperTag: props.tag,
	lineTag: "span",
});
</script>

<style lang="postcss">
.appear-heading {
	.v3sp-w {
		overflow: hidden;
	}
	.v3sp-c {
		transition-property: transform;
		transition-duration: 600ms;
		transition-delay: calc(100ms + 0.02s * (var(--char-index)));
		transform: translateY(100%);
	}

	&.is-visible {
		.v3sp-c {
			transform: translateY(0%);
		}
	}
}
</style>
