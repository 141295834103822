import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import axios from "axios";

export const useRestApi = () => {
	const restUrl = computed(() => usePage().props.restApi?.restUrl);
	const restNonce = computed(() => usePage().props.restApi?.restNonce);

	return axios.create({
		baseURL: `${restUrl.value}/`,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"X-WP-Nonce": restNonce.value,
		},
	});
};
