<template>
	<div
		ref="imageRef"
		class="my-0 not-prose w-full h-full relative"
		:class="{
			'scw-image--use-max': props.useMax,
		}"
		:style="imageRootStyles"
	>
		<template v-if="enteredViewport || isSSR || props.eager">
			<Transition
				enter-active-class="transition-opacity duration-1000"
				leave-active-class="transition-opacity duration-1000"
				enter-from-class="opacity-0"
				leave-to-class="opacity-0"
			>
				<div v-show="isLoaded" class="w-full h-full">
					<picture>
						<source type="image/webp" :srcset="webpSrcset" />
						<source :srcset="imageSrcset" />
						<img
							v-if="hasMedia"
							v-bind="$attrs"
							:class="[props.contain ? 'object-contain' : 'object-cover', props.elementClass]"
							:src="`${props.media.sizes.full.url}`"
							:alt="metadata.alt"
							:caption="metadata.caption"
							:description="metadata.description"
							@load="isLoaded = true"
						/>
					</picture>
				</div>
			</Transition>
		</template>
		<div v-if="$slots.default" class="absolute inset-0">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
import { useElementVisibility } from "@vueuse/core";
import { useImageSrcsets } from "composables";

const props = defineProps({
	contain: {
		type: Boolean,
		default: false,
	},
	eager: {
		type: Boolean,
		default: false,
	},
	media: {
		type: Object,
		default: () => ({}),
	},
	elementClass: {
		type: String,
		default: "w-full h-full",
	},
	useMax: {
		type: Boolean,
		default: false,
	},
});

const isSSR = typeof window === "undefined";
const hasMedia = computed(() => !!props?.media?.sizes?.medium?.url);
const metadata = computed(() => props.media.metadata || {});

let imageRef = ref(null);
let isLoaded = ref(props.eager === true);
let enteredViewport = ref(false);

const imageIsVisible = useElementVisibility(imageRef);
watch(imageIsVisible, (v) => {
	if (v) enteredViewport.value = true;
});

const { imageSrcset, webpSrcset } = useImageSrcsets(props.media);
const maxWidth = computed(() =>
	props.media?.sizes ? Math.max(...Object.values(props.media.sizes).map((m) => m.width)) : 0
);
const maxHeight = computed(() =>
	props.media?.sizes ? Math.max(...Object.values(props.media.sizes).map((m) => m.height)) : 0
);

const imageRootStyles = computed(() => ({
	"--scw-image--width": `${maxWidth.value}px`,
	"--scw-image--height": `${maxHeight.value}px`,
}));
</script>
