<template>
	<div v-show="image.exists" class="relative mt-2 mx-[calc(50%_-_50vw)]">
		<div class="relative">
			<div class="h-full max-h-full relative bg-gradient-to-tr from-primary-900 to-secondary-900 aspect-video">
				<ScwDisplayResponsiveImage v-if="image && image.exists" :media="image" />
				<div class="overlay absolute inset-0"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";

const image = computed(() => usePage().props.page?.featuredImage || { exists: false });
</script>

<style lang="postcss" scoped>
.overlay {
	background-image: linear-gradient(to top, transparent 0%, transparent calc(100% - 150px), rgba(0, 0, 0, 0.9));
}
</style>
