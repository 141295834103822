<template>
	<div v-show="!props.flat || image.exists" class="relative" :class="wrapperClass">
		<div
			class="relative"
			:class="{
				'-translate-y-1/4': props.variant,
				'scale-75': !props.dense && props.variant,
				'scale-50': props.dense && props.variant,
			}"
		>
			<div
				v-if="props.flat !== true"
				class="upper-shape absolute inset-0 bg-gradient-to-tr from-secondary-900 to-primary-900"
				:class="upperClass"
			></div>
			<div
				class="lower-shape h-full max-h-full relative bg-gradient-to-tr from-primary-900 to-secondary-900"
				:class="{
					'translate-y-1/4': props.variant,
					variant: props.variant,
					'aspect-square': !props.flat,
					'aspect-video': props.flat,
				}"
			>
				<ScwDisplayResponsiveImage v-if="image" :media="image" />
				<div class="overlay absolute inset-0"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { useClassConstructor } from "composables";

const props = defineProps({
	dense: {
		type: Boolean,
		default: false,
	},
	variant: {
		type: Boolean,
		default: false,
	},
	flat: {
		type: Boolean,
		default: false,
	},
});

const image = computed(() => usePage().props.page?.featuredImage || { exists: false });
const wrapperClass = computed(() =>
	useClassConstructor([
		[
			["origin-top-left pointer-events-none", props.variant && !props.flat],
			["origin-top-left scale-125 pointer-events-none translate-x-1/4 w-full", !props.flat],
		],
		["is-flat", props.flat],
	])
);
const upperClass = computed(() =>
	useClassConstructor([[["-translate-y-1/4 variant", props.variant], ["-translate-y-1/2"]]])
);
</script>

<style lang="postcss">
.lower-shape {
	clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 50%);
}
.lower-shape.variant {
	clip-path: polygon(
		45% 55%,
		0 30%,
		0 0,
		45% 25%,
		45% 55%,
		/* part 2 */ 55% 55%,
		55% 25%,
		100% 0%,
		100% 30%,
		55% 55%
	);
}

.upper-shape {
	clip-path: polygon(0 100%, 100% 50%, 100% 0, 0% 50%);
}
.upper-shape.variant {
	clip-path: polygon(45% 55%, 0% 80%, 0% 50%, 45% 25%, 45% 55%, 55% 55%, 55% 25%, 100% 50%, 100% 80%, 55% 55%);
}

.is-flat {
	margin: 0 calc(50% - 50vw);

	.upper-shape,
	.lower-shape {
		clip-path: unset;
	}
}

.overlay {
	background-image: linear-gradient(to top, transparent 0%, transparent calc(100% - 150px), rgba(0, 0, 0, 0.9));
}
</style>
