<template>
	<div
		class="radio-buttons flex gap-x-8 gap-y-2"
		:class="{
			'flex-col': props.vertical,
		}"
	>
		<button
			v-for="item in props.items"
			:key="item.label"
			v-ripple="{
				class: 'text-primary-900/40',
			}"
			class="cursor-pointer px-4 py-2 ring-dark-300 ring-1 rounded-lg transition-all duration-300 min-w-[60px] mx-2 hover:bg-primary-900/10"
			:class="{
				'!ring-primary-900 !ring': props.multiple
					? internalValue.includes(item.value)
					: internalValue === item.value,
			}"
			@click="toggleValue(item)"
		>
			{{ item.label }}
		</button>
	</div>
</template>

<script setup>
import { useVModel } from "@vueuse/core";
import { vRipple } from "directives";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
	items: {
		type: Array,
		default: () => [],
	},
	modelValue: {
		type: [Number, String, Array, Object, Boolean],
		default: null,
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	vertical: {
		type: Boolean,
		default: false,
	},
});

let internalValue = useVModel(props, "modelValue", emit);

const toggleValue = (item) => {
	if (props.multiple) toggleMultiple(item);
	else toggleSingle(item);
};

const toggleMultiple = (item) => {
	if (Array.isArray(internalValue.value) === false) internalValue.value = [];
	const isSelected = internalValue.value.includes(item.value);
	if (isSelected) internalValue.value = internalValue.value.filter((selected) => selected !== item.value);
	else internalValue.value.push(item.value);
};

const toggleSingle = (item) => {
	if (internalValue.value === item.value && props.mandatory !== true) {
		internalValue.value = null;
	} else internalValue.value = item.value;
};
</script>
