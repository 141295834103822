<template>
	<header
		ref="navRef"
		class="main-navigation w-full justify-center z-50 transition-colors duration-300"
		:class="[
			headerClasses,
			{
				'shadow-md': navIsPinned && !props.fixed,
			},
		]"
		:style="{
			top: '-1px',
			paddingTop,
		}"
	>
		<div class="lg:container relative flex justify-between items-center h-12">
			<Transition
				enter-active-class="transition-opacity duration-500"
				leave-active-class="transition-opacity duration-500"
				enter-from-class="opacity-0"
				leave-to-class="opacity-0"
			>
				<Link v-show="navIsPinned" class="w-48" href="/">
					<img v-if="mq.md" :src="Logo" class="h-12 px-4" alt="southcoastweb's logo" />
					<img v-else :src="TextLogo" alt="southcoastweb's logo" />
				</Link>
			</Transition>
			<div class="flex-grow"></div>
			<nav v-if="mq.mdPlus" class="transition-all duration-500 ease-out absolute" :class="navigationClasses">
				<ul class="flex items-center gap-x-2">
					<li v-for="item in navMenu.items" :key="item.ID" class="flex text-lg leading-tight">
						<ScwInlineLink class="px-4 whitespace-nowrap" :label="item.title" :href="item.url" />
					</li>
					<li class="mb-1.5">
						<ScwInputSearch />
					</li>
				</ul>
			</nav>
			<ScwNavigationHamburger v-else></ScwNavigationHamburger>
		</div>
	</header>
</template>

<script setup>
import { useMenuByLocation, useClassConstructor } from "composables";
import TextLogo from "icons/scw-logo-text-dark.svg";
import Logo from "icons/scw-logo.svg";

import { Link } from "@inertiajs/vue3";
import { useMq } from "vue3-mq";
import { useAppStateStore } from "store";

const appState = useAppStateStore();
const mq = useMq();

const props = defineProps({
	fixed: {
		type: Boolean,
		default: false,
	},
});

const navMenu = useMenuByLocation("header-nav");
let navIsPinned = ref(props.fixed === true);

const headerClasses = computed(() =>
	useClassConstructor([
		[["bg-white/50 backdrop-blur", !appState.mobileMenuVisible]],
		[["md:fixed  md:border-b", props.fixed], ["sticky"]],
	])
);

const navigationClasses = computed(() =>
	useClassConstructor([[["right-0", navIsPinned.value], ["right-1/2 translate-x-1/2"]]])
);

const paddingTop = computed(() => (mq.smMinus ? "0.5rem" : "calc(var(--wp-admin--admin-bar--height, 0px) + 0.5rem)"));

const attachObserver = () => {
	if (props.fixed === true) return;
	const observer = new IntersectionObserver(
		([e]) => {
			navIsPinned.value = e.intersectionRatio < 1 && e.boundingClientRect.top === -1;
		},
		{ threshold: [1] }
	);

	observer.observe(navRef.value);
};

const navRef = ref(null);
onMounted(attachObserver);
</script>

<style lang="postcss">
.main-navigation {
	.word {
		@apply inline-flex;
	}
}
</style>
