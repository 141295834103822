import { defineStore } from "pinia";
const mockStorage = {
	getItem() {},
	setItem() {},
};

const storeDefaults = () => ({
	currentQuestionKey: "has-website",
	history: [],
	hasWebsite: null,
	newWebsite: null,
	updateWebsite: null,
	businessSize: null,
	services: [],
	isEcommerce: null,
	bookingSystem: null,
	storeSize: null,
});

export const useSurveyStore = defineStore("surveyStore", {
	state: () => {
		return storeDefaults();
	},
	actions: {
		reset() {
			const defaults = storeDefaults();
			for (let key in defaults) {
				this[key] = defaults[key];
			}
		},
	},
	persist: {
		enabled: true,
		strategies: [{ storage: typeof window !== "undefined" ? localStorage : mockStorage }],
	},
});
