<template>
	<teleport to="head">
		<link
			v-for="stylesheet in styles"
			:key="stylesheet.handle"
			:head-key="stylesheet.handle"
			:href="stylesheet.src"
			rel="stylesheet"
		/>
	</teleport>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { useScriptTag } from "@vueuse/core";

import { useAppStateStore } from "store/appState";
const appState = useAppStateStore();

const scripts = computed(() => usePage().props.queue?.scripts || []);
const callbacks = computed(() => usePage().props.queue?.callbacks || []);

let loaders = [],
	unloaders = [];

scripts.value.forEach((script) => {
	const { load, unload } = useScriptTag(script.src, undefined, { manual: true, attrs: script.attributes || {} });
	loaders.push(load);
	unloaders.push(unload);
});

watch(
	() => appState.isCmsLoaded,
	(v) => {
		if (v) {
			loaders.forEach((loader) => loader());
			callbacks.value.forEach(([obj, fn]) => {
				setTimeout(() => {
					if (typeof window !== "undefined" && window[obj]) {
						window[obj][fn]();
					}
				}, 500);
			});
		} else unloaders.forEach((unloader) => unloader());
	}
);

const styles = computed(() => usePage().props.queue?.styles || []);
</script>
