export const useClassConstructor = (schema) => {
  const pass = (cond) => cond || typeof cond === "undefined";
  const process = ([cls, condition], list) =>
    cls &&
    pass(condition) &&
    list.push(
      ...cls
        .split(" ")
        .map((cls) => cls.trim())
        .filter((cls) => !!cls)
    );

  return schema.reduce((list, block) => {
    if (!Array.isArray(block)) list.push(block);
    else if (Array.isArray(block[0])) {
      for (let subblock of block) {
        const br = process(subblock, list);
        if (br) break;
      }
    } else process(block, list);
    return list;
  }, []);
};
