<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 67 67">
		<defs>
			<linearGradient :id="gradientIdOne" x1="2" y1="25.5" x2="65" y2="25.5" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#18a8e4" />
				<stop offset="1" stop-color="#9d00d3" />
			</linearGradient>
			<linearGradient :id="gradientIdTwo" x1="33.5" y1="42.5" x2="33.5" y2="8.5" gradientUnits="userSpaceOnUse">
				<stop offset="0.35" stop-color="#1d1d1f" stop-opacity="0.2" />
				<stop offset="0.54" stop-color="#1d1d1f" stop-opacity="0" />
			</linearGradient>
			<linearGradient
				:id="gradientIdThree"
				x1="63.88"
				y1="42.59"
				x2="0.88"
				y2="42.59"
				:xlink:href="`#${gradientIdOne}`"
			></linearGradient>
		</defs>
		<g data-name="Icon Primary">
			<path
				:style="{
					fill: gradientUrlOne,
				}"
				class="cls-1"
				d="M32,25.41,2,42.5V25.59L32,8.5Zm3,0L65,42.5V25.59L35,8.5Z"
			></path>
			<path
				:style="{
					fill: gradientUrlTwo,
				}"
				class="cls-2"
				d="M32,25.41,2,42.5V25.59L32,8.5Zm3,0L65,42.5V25.59L35,8.5Z"
			></path>
			<path
				:style="{
					fill: gradientUrlThree,
				}"
				class="cls-3"
				d="M65,42.5,35,59.59V42.68L65,25.59Zm-63,0L32,59.59V42.68L17,34.12,2,25.59Z"
			></path>
		</g>
	</svg>
</template>

<script setup>
import { randomString } from "helpers";

const gradientIdOne = ref(randomString(8, false));
const gradientUrlOne = computed(() => `url(#${gradientIdOne.value})`);
const gradientIdTwo = ref(randomString(8, false));
const gradientUrlTwo = computed(() => `url(#${gradientIdTwo.value})`);
const gradientIdThree = ref(randomString(8, false));
const gradientUrlThree = computed(() => `url(#${gradientIdThree.value})`);
</script>
