<template>
	<Transition
		enter-active-class="transition-all duration-300"
		leave-active-class="transition-all duration-300"
		enter-from-class="opacity-0 -translate-y-full scale-y-0"
		leave-to-class="opacity-0 -translate-y-full scale-y-0"
		@after-enter="emit('update:isShaking', true)"
	>
		<p v-if="normalisedError" :key="normalisedError" class="mt-2 text-xs absolute text-red-600 dark:text-red-400">
			{{ normalisedError }}
		</p>
	</Transition>
</template>

<script setup>
const emit = defineEmits(["update:isShaking"]);
const props = defineProps({
	error: {
		type: [Array, String],
		default: null,
	},
	isShaking: {
		type: Boolean,
		default: false,
	},
});

const normalisedError = computed(() => (Array.isArray(props.error) ? props.error[0] : props.error));
</script>
