<template>
	<component
		:is="component"
		ref="linkRef"
		class="hover-slide py-2 focus:ring focus:ring-secondary-900 focus:rounded"
		:class="{
			'is-active': isHovered,
		}"
		:href="linkHref"
		:preserve-scroll="props.scrollTop === false"
		:target="props.target"
		:aria-label="labelText"
	>
		<Splitting />
	</component>
</template>

<script setup>
import { useElementHover } from "@vueuse/core";
import { Link as InertiaLink } from "@inertiajs/vue3";
import { useNormalisedContent } from "composables";
import { useSplitting } from "vue3-splitting";
import { ref } from "vue";

const props = defineProps({
	href: {
		type: [String, Boolean],
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	scrollTop: {
		type: Boolean,
		default: false,
	},
	target: {
		type: String,
		default: "",
	},
});

const linkRef = ref(null);
const isHovered = useElementHover(linkRef);
const linkHref = computed(() => (props.href ? props.href : "#"));
const component = computed(() => (props.target || /^#/.test(linkHref.value) ? "a" : InertiaLink));

const labelText = useNormalisedContent(props);

const { Splitting } = useSplitting(labelText.value, {
	wrapperClass: "whitespace-nowrap",
	lines: false,
	words: false,
});
</script>
