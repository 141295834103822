import { defineStore } from "pinia";
const mockStorage = {
	getItem() {},
	setItem() {},
};

export const useSettingsStore = defineStore("settingsStore", {
	state: () => {
		return {
			pageTransitions: true,
		};
	},
	persist: {
		enabled: true,
		strategies: [{ storage: typeof window !== "undefined" ? localStorage : mockStorage }],
	},
});
