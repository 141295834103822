<template>
	<div
		ref="searchContainerRef"
		class="flex items-center border rounded transition-all duration-300 relative"
		:class="wrapperClass"
	>
		<div>
			<Transition
				enter-active-class="transition-all duration-300 ease-bounce"
				leave-active-class="transition-all duration-300 ease-in"
				enter-from-class="w-0"
				enter-to-class="w-80"
				leave-to-class="w-0"
				leave-from-class="w-80"
				@after-enter="onInputEnter"
			>
				<div v-if="showSearch" class="overflow-hidden">
					<input
						ref="inputRef"
						v-model="query"
						type="text"
						class="block px-2.5 text-sm appearance-none bg-transparent focus:outline-none peer w-80"
						placeholder="Search pages, posts, services and case studies"
					/>
				</div>
			</Transition>
		</div>
		<div>
			<ScwButton
				text
				icon
				text-class="text-current"
				:plain="showSearch"
				:aria-pressed="showSearch"
				aria-label="Toggle search bar"
				@click="toggleSearch"
			>
				<template #icon>
					<SvgIcon ref="buttonRef" :path="mdiMagnify" />
				</template>
			</ScwButton>
		</div>
		<Transition
			enter-from-class="opacity-0"
			leave-to-class="opacity-0"
			enter-active-class="transition-opacity"
			leave-active-class="transition-opacity"
		>
			<div v-if="hasResults" class="absolute top-full right-0 -left-full bg-white mt-1 rounded shadow">
				<ScwNavigationSearchResults
					:total="total"
					:results="results"
					:is-truncated="isTruncated"
					:estimated-server-total="estimatedServerTotal"
					:is-searching="isSearching"
					:has-results="hasResults"
					@clear:query="query = ''"
					@focus:input="inputRef.focus()"
				/>
			</div>
		</Transition>
	</div>
</template>

<script setup>
import { mdiMagnify } from "@mdi/js";
import { useClassConstructor, useSearch } from "composables";
import { onClickOutside } from "@vueuse/core";

let inputRef = ref(null);
let showSearch = ref(false);
let searchContainerRef = ref(null);

const toggleSearch = () => {
	showSearch.value = !showSearch.value;
};
const onInputEnter = () => {
	inputRef.value.focus();
};

const wrapperClass = computed(() =>
	useClassConstructor([[["border-transparent", !showSearch.value], ["border-secondary-900 bg-white"]]])
);

const { query, results, isSearching, total, hasResults, isTruncated, estimatedServerTotal } = useSearch("all");

onClickOutside(searchContainerRef, () => {
	showSearch.value = false;
	query.value = "";
});
</script>
